import React from 'react'
import { connect } from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem';
import { Button, CircularProgress, IconButton, Badge, TextField, InputLabel, Select, OutlinedInput }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import { fade, makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { isEvenOrOddWeek, mtcFrequencies, daysOfTheWeek } from '../../../services/common'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  modalContainer: {
    margin: '10px'
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: '100%'
  }
}))

const EditJobsiteModal = (props) => {
  const classes = useStyles()
  // console.log('Edit jobsite modal', props)
  const defaultState = {
    jobSite: null,
    frequency: '',
    preferredDay: '',
    preferredDays: [],
    amount: 0,
    amountType: '',
    crew: {},
    crewName: '',
    evenOrOddWeeks: 'Even'
  }
  // console.log('edit Jobsite Modal', props)
  const [state, setState] = React.useState(defaultState)

  // React.useEffect(() => {
  //   let ourCrew = {}
  //   console.log('Use Effect...props.jobsite', props.activeSite)
  //   if (props.activeSite && props.activeSite.crew) {
  //     console.log('Find Index for thisOne:', props.activeSite.crew)
  //     let index = props.crewList.findIndex(item => (item._id === props.activeSite.crew))
  //     console.log('index:', index)
  //     if (index > -1) {
  //       ourCrew = props.crewList[index]
  //     }
  //   }
  //   console.log('Crew to set:', ourCrew)
  //   setState({ ...state, ...props.activeSite, crew: ourCrew })
  // }, [props.activeSite])

  const handleClose = () => {
    props.closeAddJobsiteModal()
  }
  const updateJobSite = (e, newSite) => {
    setState({...state, jobSite: newSite })
  }

  const handleSaveJobsite = () => {
    // let site = state.jobSite
    // site.freq
    // let siteOptions = state
    // console.log('Save Jobsite:', state)
    let site = {...props.activeSite}
    let newState = {...state}
    newState.jobSite = site
    props.updateParent(newState)
    setState(defaultState)
  }

  const updateJobSiteOptions = (data) => {
  // console.log('Update our jobsiteOptions 408', data)
    setState({...state, ...data})
  }

  return (
    <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
      <DialogTitle>Modify Jobsite Options</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.modalContainer}>
          <DialogContentText>Please Modify The Items Needed On This Maintenance Agreement</DialogContentText>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Link 
              style={{pointer: 'cursor'}}
              target="_blank"
              to={{
                    pathname: '/clients/' + props.activeSite.client,
                    // search: '?sort=name',
                    // hash: '#the-hash',
                    state: {customer: null, stuff: 'HMI Crew Assignment'}
                  }}> 
                   {props.activeSite.name} - {props.activeSite.address} {props.activeSite.buildingNumber ? `(${props.activeSite.buildingNumber})` : null}
                  
            </Link>
           
           
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {
              (props.activeSite && props.activeSite._id) ? (
                <JobSiteOptionDetails crews={props.crews} jobSite={props.activeSite} updateParent={updateJobSiteOptions} />
              ) : null
            }
          </Grid>
        </Grid>
        
        

      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSaveJobsite}
          color="primary"
          disabled={!state.frequency || !state.preferredDay || !state.amountType || !state.amount}
        >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const JobSiteOptionDetails = (props) => {
  // console.log('Jobsite options:', props)
  const classes = useStyles()
  let jobSite = props.jobSite
  const [state, setState] = React.useState({
    frequency: '',
    irrigationCheckFrequency: 'Monthly',
    amount: '',
    preferredDay: '',
    preferredDays: [],
    amountType: '',
    crew: {},
    mowingRequirements: 'None',
    mowingTime: 0,
    crewSize: 0,
    estimatedHours: 0
  })

  React.useEffect(() => {
    if (props.jobSite && props.jobSite._id) {
      // console.log('Jobsite to Edit!!', jobSite)
      let ourCrew = {}
      if (props.jobSite && props.jobSite.crew) {
        // console.log('Find Index for thisOne:', props.jobSite.crew)
        let index = props.crews.findIndex(item => (item._id === props.jobSite.crew))
        // console.log('index:', index)
        if (index > -1) {
          ourCrew = props.crews[index]
        }
      }
      setState({...state, mowingRequirements: jobSite.mowingRequirements, mowingTime: jobSite.mowingTime, crewSize: jobSite.crewSize, estimatedHours: jobSite.estimatedHours, frequency: jobSite.frequency, evenOrOddWeeks: jobSite.evenOrOddWeeks, irrigationCheckFrequency: jobSite.irrigationCheckFrequency, preferredDay: jobSite.preferredDay, preferredDays: jobSite.preferredDays || [], amount: jobSite.amount, amountType: jobSite.amountType, crew: ourCrew })
    }
  }, [jobSite])

  const handleChange = (e, type) => {
    let newState = {...state}
    newState[type] = e.target.value
  console.log('Set State 165', newState)
    setState({...state, ...newState})
    props.updateParent(newState)
  }

  const updateCrew = (e, newCrew) => {
    let newState = {...state, crew: newCrew}
    if (newCrew?._id) {
      newState.crew = newCrew
      newState.crewName = `${newCrew.name || 'Unassigned'} ${newCrew.foremanName ? `(${newCrew.foremanName})` : ''}`
    } else {
      newState.crew = null
      newState.crewName = 'Unassigned'
    }
    setState(newState)
    props.updateParent(newState)
  }

  const handlepreferredDayChange = (e) => {
    let newState = { ...state, preferredDay: e.target.value }
    setState(newState)
    props.updateParent(newState)
  }

  const handlepreferredDaysChange = (e) => {
    // console.log('Preferred days', e.target.value)
    let newState = { ...state, preferredDays: e.target.value, preferredDay: e.target.value[0] }
    setState(newState)
    props.updateParent(newState)
  }

  const weekType = isEvenOrOddWeek()
  
  const handleFocus = (event) => event.target.select();
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="frequency"
            label="Visit Frequency"
            type="text"
            fullWidth
            select
            variant="outlined"
            value={state.frequency}
            className={classes.textField}
            // onChange={ handleChange }
            onChange={ e => handleChange(e, 'frequency') }
            // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
            InputLabelProps={{
              shrink: true,
            }}
            // onBlur={updateParent}
          >
           {
            mtcFrequencies.map((item, index) => {
              return (
                <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
              )
            })
          }
          </TextField>

        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
              state.frequency === 'Bi-Weekly' ? (
                <FormControl className={classes.paddedStuff} fullWidth>
              <TextField
                type="select"
                select
                className={classes.textField}
                variant="outlined"
                value={state.evenOrOddWeeks}
                onChange={ e => handleChange(e, 'evenOrOddWeeks') }
                label="Week Assignment"
                // onChange={(e) => setState({...state, phone['type']: e.target.value })}
                // className={classes.paddedStuff}
                inputProps={{ 'aria-label': 'Odd or Even Weeks' }}
                fullWidth
                placeholder="Choose Which Week"
              >
                
                <MenuItem value={"Odd"}>
                  Odd
                </MenuItem>
                <MenuItem value={"Even"}>Even</MenuItem>
              </TextField>
              <FormHelperText>Odd Or Even Weeks of the Year (This week is {weekType})</FormHelperText>
            </FormControl>
              ) : ''
            }
        </Grid>
      
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            state.frequency === 'Twice a Week' ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="preferredDay-label">Preferred Days</InputLabel>
                <Select
                  labelId="preferredDay-label"
                  id="preferredDay"
                  multiple
                  value={state.preferredDays} // This should be an array
                  onChange={handlepreferredDaysChange}
                  input={<OutlinedInput label="Preferred Days" />}
                  renderValue={(selected) => selected.join(', ')} // Display selected items
                >
                  {daysOfTheWeek.map((item, index) => (
                    <MenuItem key={index} value={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : state.frequency === 'Every Other Day (M-W-F)' ? (
              <TextField
                  id="preferredDay"
                  label="Preferred Day"
                  type="text"
                  fullWidth
                  variant="outlined"                  
                  value={'Monday Wednesday Friday'}
                  className={classes.textField}
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled
                  // onBlur={updateParent}
                />
            ) : (
              <TextField
                id="preferredDay"
                label="Preferred Day"
                type="text"
                fullWidth
                variant="outlined"
                select
                value={state.preferredDay}
                className={classes.textField}
                onChange={ handlepreferredDayChange }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                // onBlur={updateParent}
              >
              {
                daysOfTheWeek.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                  )
                })
              }
              </TextField>
            )
          }

        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            id="crew"
            options={props.crews || []}
            getOptionLabel={(option) => {
              return `${option.name} ${option.foremanName ? `(${option.foremanName})` : ''}`
            }}
            // style={{ width: 200 }}
            // className={classes.paddedStuff}
            // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
            onChange={updateCrew}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.crew}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Assigned Crew" />}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="amountType"
            label="Amount Type"
            fullWidth
            // className={classes.paddedStuff}
            // type="number"
            select
            defaultValue={false}
            variant="outlined"
            value= {state.amountType}
            className={classes.textField}
            helperText="Is this a Monthly Biller or a Per Visit?"
            onChange={ e => handleChange(e, 'amountType') }
            // onChange={ handleChange }
            >
              <MenuItem key="monthly" value={'monthly'}>Monthly</MenuItem>
              <MenuItem key="perVisit" value={'perVisit'}>Per Visit</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="amount"
            label="Amount"
            type="number"
            fullWidth
            variant="outlined"
            value={state.amount}
            className={classes.textField}
            onChange={ e => handleChange(e, 'amount') }
            // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
            InputLabelProps={{
              shrink: true,
            }}
            // onBlur={updateParent}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="irrigationCheck"
            label="Irrigation Check Frequency"
            fullWidth
            // className={classes.paddedStuff}
            // type="number"
            select
            defaultValue={false}
            variant="outlined"
            value= {state.irrigationCheckFrequency}
            className={classes.textField}
            helperText="How Often Will We Do Irrigation Checks?"
            onChange={ e => handleChange(e, 'irrigationCheckFrequency') }
            // onChange={ handleChange }
            >
              <MenuItem key="none" value={'None'}>None</MenuItem>
              <MenuItem key="monthly" value={'Monthly'}>Monthly</MenuItem>
              <MenuItem key="quarterly" value={'Quarterly'}>Quarterly</MenuItem>
              <MenuItem key="semi" value={'Semi-Annually'}>Semi-Annually</MenuItem>
              <MenuItem key="annually" value={'Annually'}>Annually</MenuItem>
            </TextField>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="crewSize"
              label="Size Of Crew"
              fullWidth
              // className={classes.paddedStuff}
              type="number"
              onFocus={handleFocus}
              defaultValue={0}
              variant="outlined"
              value= {state.crewSize}
              className={classes.textField}
              helperText="How Large Is the Crew?"
              onChange={ e => handleChange(e, 'crewSize') }
              // onChange={ handleChange }
            />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="estimatedHours"
              label="Hours Required"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              type="number"
              defaultValue={0}
              variant="outlined"
              onFocus={handleFocus}
              value= {state.estimatedHours}
              className={classes.textField}
              helperText="How Many Hours Per Visit?"
              onChange={ e => handleChange(e, 'estimatedHours') }
              // onChange={ handleChange }
              />
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="mowingRequirements"
              label="Mowing Requirements"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              select
              defaultValue={false}
              variant="outlined"
              value= {state.mowingRequirements}
              className={classes.textField}
              helperText="What are the Mowing Requirements?"
              onChange={ e => handleChange(e, 'mowingRequirements') }
              // onChange={ handleChange }
              >
                <MenuItem key="none" value={'None'}>None</MenuItem>
                <MenuItem key="onDemand" value={'On Demand'}>On Demand</MenuItem>
                <MenuItem key="weekly" value={'Weekly'}>Weekly</MenuItem>
              </TextField>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
              id="mowingTime"
              label="Mowing Time"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              type="number"
              disabled={!state.mowingRequirements}
              defaultValue={0}
              variant="outlined"
              onFocus={handleFocus}
              value= {state.mowingTime}
              className={classes.textField}
              helperText="How Many Hours Per Mow?"
              onChange={ e => handleChange(e, 'mowingTime') }
              // onChange={ handleChange }
              />
          </Grid>
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    crews: state.crews,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(EditJobsiteModal)
