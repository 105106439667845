exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".profile_profile__3FVxH {\n  margin: 20px; }\n\n.profile_button__287TB {\n  margin-right: 20px !important; }\n\n.profile_link__3T-Bj {\n  color: inherit;\n  text-decoration: none;\n  cursor: inherit; }\n\n.profile_card__2sAqI {\n  width: 400px; }\n", ""]);

// Exports
exports.locals = {
	"profile": "profile_profile__3FVxH",
	"button": "profile_button__287TB",
	"link": "profile_link__3T-Bj",
	"card": "profile_card__2sAqI"
};