import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import axios from 'axios'
import moment from 'moment';
import CustomMarker from './CustomMarker'
import { isEven, findNextAppropriateDate } from '../../../services/common'
import ServiceScheduler from './ServiceScheduler';
import { addDays, subDays } from 'date-fns';
import AssignmentConfirmationDialog from './AssignmentConfirmationDialog';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import SelectedCrewDetails from './SelectedCrewDetailsContainer'
import UnassignmentConfirmationModal from './ConfirmUnassignmentDialog'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { ArrowUpward, ArrowDownward, Sort } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    maxHeight: '90vh',
    // backgroundColor: 'pink',
    paddingBottom: '10px',
    marginBottom: '5px',
    // overflow: 'hidden'
  },
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: theme.spacing(2),
  },
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    // overflow: 'hidden',
  },
  calendarPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#ccc',
    minHeight: '20vh',
    maxHeight: '30vh',
    marginBottom: 5
  },
  crewPaper: {
    // padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#eee',
    width: '100%',
    paddingBottom: 80,
    marginBottom: 80,
  },
  unassignedJobsPaper: {
    // padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#eee',
    maxHeight: '90vh',
    padding: 5,
    width: '100%',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px', // Make the scrollbar skinny (adjust the width as needed)
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)', // Thumb color (scrollbar handle)
      borderRadius: '10px', // Rounded corners for the scrollbar handle
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Track color (scrollbar background)
    },
    '-ms-overflow-style': '-ms-autohiding-scrollbar',  // Thin scrollbar for IE and Edge
    'scrollbar-width': 'thin',     // Thin scrollbar for Firefox
  },
  unassignedJobsHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: 5,
    margin: 5,
    alignItems: 'center'
  },
  mapContainer: {
    position: 'relative',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
  },
  map: {
    flexGrow: 1,
    minHeight: '50%',
    width: '100%',
  },
  selectedCrewContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    zIndex: 1,
    boxShadow: theme.shadows[4],
    maxHeight: '50%',
    overflowY: 'hidden',
    transition: 'height 0.3s ease-in-out',
  },
  mapOverlayActionArea: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 2,
  },
  toggleButton: {
    position: 'absolute',
    top: -28,
    right: theme.spacing(2),
    zIndex: 3,
  },
  unassignedJobItem: {
    border: '2px solid #000',
    margin: 5,
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: 12
  },
  activeJobItem: {
    border: '3px solid red',
    margin: 5,
    padding: 5,
    backgroundColor: '#ddd',
    borderRadius: 12
  },
  crewList: {
    backgroundColor: '#eee',
    margin: 3,
    padding: 5,
    // width: '100%',
    // flexGrow: 1,
    maxHeight: '65vh',
    overflow: 'auto',
    // paddingBottom: 80,
    // marginBottom: 80,
    '&::-webkit-scrollbar': {
      width: '4px', // Make the scrollbar skinny (adjust the width as needed)
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)', // Thumb color (scrollbar handle)
      borderRadius: '10px', // Rounded corners for the scrollbar handle
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Track color (scrollbar background)
    },
    '-ms-overflow-style': '-ms-autohiding-scrollbar',  // Thin scrollbar for IE and Edge
    'scrollbar-width': 'thin',     // Thin scrollbar for Firefox
  },
  crewDetailsTopLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  crewDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  crewBlockColor: {
    width: '80px',
    height: '80px',
    borderRadius: 5
  },
  dateArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-evenly'
  },
  mapOverlayActionArea: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    top: 30,
    left: 250,
    // width: '250px',
    // height: '80px',
    // backgroundColor: 'rgba(0, 0, 0, 0.09)',
    // border: '3px solid rgba(0, 0, 0, 0.09)',
    // borderRadius: 5,
    // margin: 5,
    // padding: 10
  },
  crewsStopsContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '200px',
    padding: 5,
    // margin: 3,
    width: '100%'
  },
  nearestSiteItemContainerOLD: {
    border: '1px solid #ddd',
    borderRadius: 5,
    marginBottom: 5,
    padding: 10,
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  nearestSiteItemContainer: {
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    overflow: 'hidden',
    cursor: 'pointer',
  },
  colorBar: {
    width: 14,
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  smallColumn: {
    flex: '0 0 20%',
    marginRight: theme.spacing(2),
  },
  largeColumn: {
    flex: 1,
  },
  infoItem: {
    marginBottom: theme.spacing(1),
  },
  infoItemClickable: {
    marginBottom: theme.spacing(1),
    cursor: 'pointer'
  },
  infoValue: {
    fontSize: '1.1rem',
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
  infoLabel: {
    fontSize: '0.65rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  siteListContainer: {
    overflow: 'auto',
    // height: '40%',
    maxHeight: '50vh',
    // backgroundColor: 'pink'
    '&::-webkit-scrollbar': {
      width: '4px', // Make the scrollbar skinny (adjust the width as needed)
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.3)', // Thumb color (scrollbar handle)
      borderRadius: '10px', // Rounded corners for the scrollbar handle
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Track color (scrollbar background)
    },
    '-ms-overflow-style': '-ms-autohiding-scrollbar',  // Thin scrollbar for IE and Edge
    'scrollbar-width': 'thin',     // Thin scrollbar for Firefox
  },
  activeMarkerContainer: {
    backgroundColor: '#fff',
    padding: 5,
    margin: 10,
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // alignItems: 'center'
    alignContent: 'center',
    margin: 5,
    padding: 5
  },
  flexRowCentered: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    // alignItems: 'center'
    alignContent: 'center',
    margin: 5,
    padding: 5
  },
  sortButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
  paperRoot: {
    backgroundColor: '#fff',
    padding: 0,
    width: '100%',
    margin: 0
  },
  tabsRootSidebar: {
    width: '100%',
    margin: 0,
    padding: 0,
    backgroundColor: 'pink'
  },
  tabPanel: {
    padding: 0,
    margin: 0,
    width: '100%',
    '& .MuiBox-root': {
      padding: '0 !important',
    },
  },
}));

const sortCrews = (crews, sortMethod, sortDirection) => {
  console.log('Sort crews', crews, sortMethod, sortDirection)
  return [...crews].sort((a, b) => {
    let aValue, bValue;
    switch (sortMethod) {
      case 'todayServiceHours':
        aValue = (a.todayData.totalServiceTime || 0) / 60;
        bValue = (b.todayData.totalServiceTime || 0) / 60;
        break;
      case 'todayTotalHours':
        aValue = ((a.todayData.totalServiceTime || 0) + (a.todayData.totalDriveTime || 0)) / 60;
        bValue = ((b.todayData.totalServiceTime || 0) + (b.todayData.totalDriveTime || 0)) / 60;
        break;
      case 'weekTotalServiceHours':
        aValue = (a.weekData.totalServiceTime || 0) / 60;
        bValue = (b.weekData.totalServiceTime || 0) / 60;
        break;
      case 'weekTotalHours':
        aValue = ((a.weekData.totalServiceTime || 0) + (a.weekData.totalDriveTime || 0)) / 60;
        bValue = ((b.weekData.totalServiceTime || 0) + (b.weekData.totalDriveTime || 0)) / 60;
        break;
      default:
        aValue = a.name;
        bValue = b.name;
    }
    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });
};


const checkForFutureVisits = (siteId, date, futureVisits) => {
  console.log('Check this date for future visits', siteId)
  console.log('future visits', futureVisits)
  console.log('site:', siteId)
  let matchingItems = futureVisits.filter(visit => (visit.siteId === siteId.toString()))
  console.log('Matches', matchingItems)
  let exists = futureVisits.some(visit => {
    const date = visit.visitDate
    const visitSite = visit.siteId
    return (visitSite === siteId.toString())
    // moment(date).year() === currentYear && moment(date).isAfter(moment())
  })
  console.log('Exists::', exists)
  return matchingItems
}

const getColor = (crewId, crews) => {
  let color = '#fff'
  if (crews?.length) {
    const crew = crews.find(crewObj => (crewObj._id === crewId))
    if (crew?.color) color = crew.color
  }
  return color
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const defaultCenter = {
  lat: 33.50547813946138,
  lng: -112.15212417301781
};

const position = {
  lat: 33.5,
  lng: -112.1
}

const days = ['M', 'T', 'W', 'Th', 'F'];
const fullDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const getBackgroundColor = (hours) => {
  if (hours > 8) return 'rgba(212,102,102,0.5)';
  if (hours > 6) return 'rgba(240,223,113,0.5)';
  if (hours > 4) return 'rgba(203,250,192,0.5)';
  return '#eee';
};

const findNextMatchingDate = (site, startDate = moment()) => {
  const { frequency, evenOrOddWeeks, preferredDay, preferredDays } = site;
  let currentDate = moment(startDate);

  const fullDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const isEvenWeek = (date) => moment(date).week() % 2 === 0;

  const getNextWeekday = (date, targetDay) => {
    console.log('Get next weekday ', date.format('YYYY-MM-DD'), targetDay);
    if (!targetDay || targetDay === 'None') {
      return date.clone(); // Return the same day if no target day specified
    }

    const targetIndex = fullDays.indexOf(targetDay.toLowerCase());
    if (targetIndex === -1) {
      throw new Error('Invalid preferred day');
    }

    let daysToAdd = targetIndex - date.day();
    if (daysToAdd <= 0) daysToAdd += 7;
    console.log('Days To Add', daysToAdd);
    return date.clone().add(daysToAdd, 'days');
  };

  const getDayToCheck = () => {
    if (preferredDay && preferredDay !== 'None') {
      return preferredDay.toLowerCase();
    }
    if (preferredDays && preferredDays.length > 0) {
      return preferredDays[0].toLowerCase();
    }
    return null; // No preference specified
  };

  const findNextDate = () => {
    const dayToCheck = getDayToCheck();
    console.log('Day to check: %s  - Frequency: %s ', dayToCheck, frequency.toLowerCase());

    switch (frequency.toLowerCase()) {
      case 'monthly':
      case 'quarterly':
      case 'semi-annually':
      case 'on-demand':
        if (!dayToCheck) return currentDate; // No matching day, use today
        let nextDate = currentDate.clone();
        if (frequency.toLowerCase() === 'monthly') nextDate.add(1, 'month').startOf('month');
        if (frequency.toLowerCase() === 'quarterly') nextDate.add(1, 'quarter').startOf('quarter');
        if (frequency.toLowerCase() === 'semi-annually') nextDate.add(6, 'months').startOf('month');
        return getNextWeekday(nextDate, dayToCheck);

      case 'weekly':
        return dayToCheck ? getNextWeekday(currentDate, dayToCheck) : currentDate;

      case 'bi-weekly':
        if (!dayToCheck) return currentDate; // No matching day, use today
        let biWeeklyDate = getNextWeekday(currentDate, dayToCheck);
        const targetEvenWeek = evenOrOddWeeks.toLowerCase() === 'even';
        while (isEvenWeek(biWeeklyDate) !== targetEvenWeek) {
          biWeeklyDate.add(1, 'week');
        }
        return biWeeklyDate;

      case 'twice a week':
        if (preferredDays?.length) {
          // Find the first day that is in our site.preferredDaysArray [Monday, Wednesday]
          const sortedPreferredDays = preferredDays
            .map(day => fullDays.indexOf(day.toLowerCase()))
            .filter(dayIndex => dayIndex !== -1) // Filter out invalid days
            .sort((a, b) => a - b); // Sort by day of the week (e.g., Monday = 1, Wednesday = 3)

          for (let i = 0; i < sortedPreferredDays.length; i++) {
            const dayIndex = sortedPreferredDays[i];
            if (dayIndex > currentDate.day()) {
              return currentDate.clone().day(dayIndex);
            }
          }
          // If both preferred days have passed in the current week, pick the first day in the next week
          return currentDate.clone().add(1, 'week').day(sortedPreferredDays[0]);
        } else {
          if (currentDate.day() < 3) { // Before Wednesday
            return currentDate.day(3); // Set to Wednesday
          } else if (currentDate.day() < 6) { // Wednesday to Friday
            return currentDate.day(6); // Set to Saturday
          } else { // Saturday or Sunday
            return currentDate.add(1, 'week').day(3); // Set to next Wednesday
          }
        }

      case 'every other day (m-w-f)':
        const nextDays = [1, 3, 5]; // Monday, Wednesday, Friday
        const nextDay = nextDays.find(day => day > currentDate.day()) || nextDays[0];
        return currentDate.day(nextDay);

      default:
        return currentDate; // For any unhandled frequency, use today
    }
  };

  return findNextDate();
};

const calculateHours = (filteredSites) => {
  let totalHours = 0;
  const crewHours = {};

  filteredSites.forEach(site => {
    const hours = parseFloat(site.estimatedHours) || 0;
    totalHours += hours;

    if (site.crew) {
      crewHours[site.crew] = (crewHours[site.crew] || 0) + hours;
    }
  });

  return { totalHours, crewHours };
};

const formatDistance = (meters) => {
  return Math.round(Number(meters / 1609) * 100) / 100
}

const defaultStateVals = {
  assigningToService: null,
  servicesRemaining: [],
  weekStart: null,
  weekEnd: null,
  activeJob: {},
  futureVisits: []
}

const SchedulerComponent = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(defaultStateVals)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [totalHours, setTotalHours] = useState(0);
  const [unassignedJobs, setUnassignedJobs] = useState([]);
  const [crews, setCrews] = useState([]);
  const [filteredCrews, setFilteredCrews] = useState([])
  const [markers, setMarkers] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState([])
  const [activeMarker, setActiveMarker] = React.useState({})
  const [sites, setSites] = useState([])
  const [selectedCrew, setSelectedCrew] = useState({})
  const [allFilteredMarkers, setAllFilteredMarkers] = useState([]);
  const [weeklyCrewHours, setWeeklyCrewHours] = useState({});
  const [zoomLevel, setZoomLevel] = useState(9)
  const [center, setCenter] = useState(defaultCenter)
  const [jobSiteDetails, setJobSiteDetails] = useState({})
  const [crewLocations, setCrewLocations] = useState([])
  const [activeRouteDetails, setActiveRouteDetails] = useState({})
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GMAPKEY
  })
  // const [map, setMap] = useState(null)
  const [weeklyRoutes, setWeeklyRoutes] = useState({});
  const [weeklyHours, setWeeklyHours] = useState({});
  const [highlightedSite, setHighlightedSite] = useState('')
  const [highlightedNearbySite, setHighlightedNearbySite] = useState('')
  const [isUnassignmentModalOpen, setIsUnassignmentModalOpen] = useState(false)
  const [sortMethod, setSortMethod] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [tabValue, setTabValue] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // setMap(map)
    mapRef.current = map;
  }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])
  const [isExpanded, setIsExpanded] = useState(false);
  const mapRef = useRef(null);

  const dayOfWeek = moment(selectedDate).format('dddd')
  let weekOfTheYear = moment(selectedDate).week()
  let evenWeek = isEven(weekOfTheYear)
  let thisWeek = evenWeek ? 'Even' : 'Odd'


  useEffect(() => {
    const today = new Date()
    fetchRoutes(today);
  }, []);


  useEffect(() => {
    // console.log('Weekly routes chagned', weeklyRoutes)
    if (weeklyRoutes.length > 0 && crews.length > 0) {
      const calculatedWeeklyHours = calculateWeeklyHours(weeklyRoutes, crews);
      setWeeklyHours(calculatedWeeklyHours);
    }
  }, [weeklyRoutes, crews]);

  const fetchRoutes = async (date = new Date(), chosenCrew) => {
    console.log('Fetch Routes', date)
    // Do we need to actually ftch the routes
    console.log('Current state', state)
    const weekStart = new Date(state.weekStart)
    const weekEnd = new Date(state.weekEnd)
    console.log('Week start', weekStart)
    console.log('Week end', weekEnd)
    if (date > weekStart && date < weekEnd) {
      console.log('No need to update the routes...')
      filterMarkers(sites, date, crews, weeklyRoutes, false, chosenCrew);
      setLoading(false)
    } else {
      try {
        setLoading(true)
        const formattedDate = date.toISOString().split('T')[0];
        const resp = await axios.get(`${props.url}/api/hmi/genericGetRequest?type=weeklyRoutesByDate&date=${formattedDate}`);
        setLoading(false)
        let newFutureVisits = [...state.futureVisits]
        console.log('500:::', resp.data)
        // let newState = {}
        // if (resp.data.data.routes?.weekStart) newState.weekStart = resp.data.data.routes.weekStart
        // if (resp.data.data.routes?.weekEnd) newState.weekEnd = resp.data.data.routes.weekEnd
        if (resp?.data?.data?.routes?.routes) {
  
          console.log('Route Response', resp.data)
          setWeeklyRoutes(resp.data.data.routes.routes);
          let uniqueCrews = crews || []
          let jobsites = sites || []
          if (resp.data.data?.futureVisits?.length) newFutureVisits = resp.data.data.futureVisits
          if (resp.data.data?.routes.sites?.length && resp.data.data.routes.sites.length !== sites.length) {
            console.log('Update Our Crews and Set our Sites too 502')
            const hmiCrews = props.crews.filter(crew => crew.entity === "640a56404965b71a9edd0e7d");
            jobsites = resp.data.data.routes.sites;
            
            const uniqueCrewIds = hmiCrews.map(crew => (crew._id)) // [...new Set(jobsites.map(site => site.crew))];
            uniqueCrews = uniqueCrewIds.map((crewId, index) => {
              const crewItem = hmiCrews.find(crew => crew._id === crewId);
              if (crewItem) {
                return {
                  ...crewItem,
                  name: `${crewItem.name} ${crewItem.foremanName ? `(${crewItem.foremanName})` : ''}`,
                  color: getCrewColor(index)
                };
              }
              return null;
            }).filter(Boolean);
            console.log('Unique Crews', uniqueCrews)
            setCrews(uniqueCrews);
            setFilteredCrews(uniqueCrews)
            setSites(jobsites);
            processMtcSites(jobsites, date, newFutureVisits)
          }
          filterMarkers(jobsites, date, uniqueCrews, resp.data.data.routes.routes, false, chosenCrew);
          setState({ weekStart: resp.data.data.routes.weekStart, weekEnd: resp.data.data.routes.weekEnd, futureVisits: newFutureVisits });
        }
      } catch (error) {
        console.error('Error fetching routes:', error);
      }
    }
  };

  const filterMarkers = (allSites, date, crewsList, routesPassed, clearingFilters, chosenCrew) => {
    const dayOfWeek = moment(date).format('dddd');
    console.log('Filter markers 536', allSites, date, crewsList, routesPassed)
    let routesToUse = routesPassed ? routesPassed : weeklyRoutes?.length ? weeklyRoutes : []
    console.log('Routes to use', routesToUse)
    const routesForDay = routesToUse.find(route => route.day === dayOfWeek);
    console.log('Clearing Filters', clearingFilters)
    console.log('Selected Crew', selectedCrew)
    if (routesForDay) {
      console.log('Routes for day', routesForDay)
      console.log('Chosen Crew', chosenCrew)
      if (((selectedCrew && selectedCrew._id) || chosenCrew?._id) && !clearingFilters) {
        let crewId = chosenCrew?._id || selectedCrew._id
        console.log('Filter By Crew Id', crewId)
        const filteredSitesForCrewAndDay = filterSitesForCrewAndDate(allSites, date, crewId, routesPassed);
        if (crewId && activeMarker?.location) {
          console.log('550 Chosen Crew...Active Marker ', activeMarker)
          console.log('Sites for this crew', filteredSitesForCrewAndDay)
          getRouteDataWithThisSite(filteredSitesForCrewAndDay, activeMarker, 644)
        }
        console.log('Set Filtered Markers:::: 646 ', filteredSitesForCrewAndDay)
        setFilteredMarkers(filteredSitesForCrewAndDay)
      } else if (selectedCrew && selectedCrew._id && !clearingFilters) {
        console.log('576 using selected crew...')
        let crewId = selectedCrew._id
        console.log('Filter By Crew Id', crewId)
        const filteredSitesForCrewAndDay = filterSitesForCrewAndDate(allSites, date, crewId, routesPassed);
        if (chosenCrew && activeMarker) {
          console.log('581 Chosen Crew...Active Marker ', activeMarker)
          console.log('Sites for this crew', filteredSitesForCrewAndDay)
          getRouteDataWithThisSite(filteredSitesForCrewAndDay, activeMarker, 656)
        }
        setFilteredMarkers(filteredSitesForCrewAndDay)
      } else {
        console.log('Show all crews')
        const markersForDay = routesForDay.routes.flatMap(route => 
          route.stops.map(stop => {
            const siteToUse = allSites.find(siteItem => (siteItem._id === stop.siteId))
            if (!siteToUse) {
              console.log('No site to use for this one....')
            } else {
              // console.log('Here it is:', siteToUse)
              return ({
                ...siteToUse,
                ...stop,
                crew: route.crewId,
                crewName: route.crewName,
                color: crewsList.find(crew => crew._id === route.crewId)?.color,
              })
            }
        }));
        console.log('markers for today 534', markersForDay)
        setFilteredMarkers(markersForDay);
      }

    } else {
      setFilteredMarkers([]);
    }
  };

  const calculateWeeklyHours = (weeklyRoutes, crews) => {
    let weeklyHours = {};
    // console.log('calculate hours!!!', weeklyRoutes)
    // Initialize weekly hours for each crew
    crews.forEach(crew => {
      weeklyHours[crew._id] = {
        Monday: { serviceTime: 0, driveTime: 0 },
        Tuesday: { serviceTime: 0, driveTime: 0 },
        Wednesday: { serviceTime: 0, driveTime: 0 },
        Thursday: { serviceTime: 0, driveTime: 0 },
        Friday: { serviceTime: 0, driveTime: 0 },
        Saturday: { serviceTime: 0, driveTime: 0 },
        Sunday: { serviceTime: 0, driveTime: 0 },
        total: { serviceTime: 0, driveTime: 0 }
      };
    });
  
    // Calculate hours for each day of the week
    weeklyRoutes.forEach(dayRoute => {
      const dayName = dayRoute.day;
      dayRoute.routes.forEach(route => {
        const crewId = route.crewId;
        if (!weeklyHours[crewId]) {
          console.error(`Undefined crewId ${crewId} on ${dayName}. Initializing...`);
          weeklyHours[crewId] = {
            Monday: { serviceTime: 0, driveTime: 0 },
            Tuesday: { serviceTime: 0, driveTime: 0 },
            Wednesday: { serviceTime: 0, driveTime: 0 },
            Thursday: { serviceTime: 0, driveTime: 0 },
            Friday: { serviceTime: 0, driveTime: 0 },
            Saturday: { serviceTime: 0, driveTime: 0 },
            Sunday: { serviceTime: 0, driveTime: 0 },
            total: { serviceTime: 0, driveTime: 0 }
          };
        }
  
        const serviceTime = route.totalServiceTime / 60; // Convert minutes to hours
        const driveTime = route.totalDriveTime / 60; // Convert minutes to hours
  
        weeklyHours[crewId][dayName].serviceTime += serviceTime;
        weeklyHours[crewId][dayName].driveTime += driveTime;
        weeklyHours[crewId].total.serviceTime += serviceTime;
        weeklyHours[crewId].total.driveTime += driveTime;
      });
    });
    console.log('Return these weekly hours', weeklyHours)
    return weeklyHours;
  };

  const processMtcSites = (sitesPassed, date, futureVisits) => {
    const dayOfWeek = moment(date).format('dddd');
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const isWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(dayOfWeek);
    const weekOfTheYear = moment(date).week();
    const evenWeek = isEven(weekOfTheYear);
    const currentYear = moment().year();
    const thisWeek = evenWeek ? 'Even' : 'Odd';
    console.log('processMtcSites:: Props::', props)
    let sitesToUse = sitesPassed?.length ? sitesPassed : sites?.length ? sites : []
    console.log('SIteS:', sitesToUse)
    let newUnassignedJobs = [];
    sitesToUse.forEach((site, index) => {
      console.log('SIte 799...', site)
      const siteDayOfWeek = moment(site.preferredDay, 'dddd');
      const frequency = site.frequency;
      let includeSite = false
      let isUnassigned = false
      console.log('706...')
      switch (frequency) {
        case 'Monthly':
        case 'Quarterly':
        case 'Semi-Annually':
        case 'Annually':
        case 'On-Demand':
          if (site.scheduledDates && site.scheduledDates.length > 0) {
            includeSite = site.scheduledDates.includes(formattedDate);
            
            
          } else {
            console.log('Check for future visits...')
            // Check if there are any future dates this year
            const hasFutureDates = checkForFutureVisits(site._id, date, futureVisits)
            console.log('Has Future Dates', hasFutureDates)
            if (hasFutureDates?.length) {
              console.log('no need')

            } else {
              // newUnassignedJobs.push(site);
              isUnassigned = true
            }
            // If no scheduled dates, add to unassigned jobs
            // newUnassignedJobs.push(site);
          }
          break;
        default:
          if (!site.crew && frequency) {
            isUnassigned = true
          }
          // includeSite = site.preferredDay === dayOfWeek || (site.preferredDays && site.preferredDays.includes(dayOfWeek));
          break;
      }
      if (site.unrouted) isUnassigned = true
      console.log('Do we include this as an unassigned job??', includeSite)
      if (isUnassigned) newUnassignedJobs.push(site)
    })
    console.log('Unassigned jobs', newUnassignedJobs)
    // console.log('Old FIltered SItes', filteredSites)
    // setUnassignedJobs()
    // setFilteredMarkers(filteredSitesNew)
    // setAllFilteredMarkers(filteredSitesNew);
    setUnassignedJobs(prevUnassignedJobs => {
      // Combine existing unassigned jobs with new ones, removing duplicates
      const combinedUnassignedJobs = [...prevUnassignedJobs, ...newUnassignedJobs];
      return Array.from(new Set(combinedUnassignedJobs.map(job => job._id)))
        .map(id => combinedUnassignedJobs.find(job => job._id === id));
    });
  }

   const handleDateChange = (date) => {
    setSelectedDate(date);
    // filterTheMarkers(markers, date, crews)
    fetchRoutes(date);
  };

  const getRouteDataWithThisSite = async (sitesPassed, addlMarker, lineCalled) => {
    console.log('Run a Re-Route w/ this data', sitesPassed)
    console.log('Line called', lineCalled)
    // console.log('Active Marker:', activeMarker)
    // console.log('Additional Marker', addlMarker)
    const combinedSitesWithNoDupes = [...sitesPassed, addlMarker];
    const sitesToUse = Array.from(new Set(combinedSitesWithNoDupes.map(job => job._id))).map(id => combinedSitesWithNoDupes.find(job => job._id === id));
  
    // console.log('Combined SItes w/ no dupes', sitesToUse)
    const routingDetails = await axios.post(`${props.url}/api/hmi/routeOptimizationRequest`, sitesToUse)
    // console.log('Got the routingDetails details response 719:::', routingDetails)
    if (routingDetails?.data?.stops?.length) setActiveRouteDetails(routingDetails.data)
  }

  const updateActiveMarker = (markerClicked) => {
    // console.log('Update active marker...', markerClicked)
    // Need to update this so that it fetches updated data for the 
    const crew = crews.find(crewItem => (crewItem._id === markerClicked.crew))
    if (crew?._id && selectedCrew && selectedCrew._id === crew._id) {
      // If the clicked crew is already selected, deselect it
      // setSelectedCrew(null);
      // Reset to show all filtered markers
      // filterTheMarkers(jobSites, selectedDate, crews);
    } else {
      setSelectedCrew(crew);
      // Only display markers associated with this crew for the current date
      // const crewSites = filterSitesForCrewAndDate(sites, selectedDate, crew._id);
      // setFilteredMarkers(crewSites);
      // if (markerClicked && markerClicked.isUnassigned) {
      //   console.log('Unassigned Marker -- lets reroute', crewSites)
      //   // getRouteDataWithThisSite(crewSites)
      // }
      filterMarkers(sites, selectedDate, crews, weeklyRoutes, false, crew);
    }

  }

  const handleClickAssign = (d) => {
    console.log('Assign this one', d)
    // let updatedMarkers = [
    //   ...filteredMarkers.filter(marker => (marker._id !== activeMarker?._id)),
    //   // d
    // ]
    const newActiveMarker = {
      ...d,
      isUnassigned: true
    }
    // setFilteredMarkers(updatedMarkers)
    setActiveMarker(newActiveMarker)
    setZoomLevel(9)
    let offset = 0.2
    if (selectedCrew?._id) offset = .353
    let lng = d?.location?.coordinates?.length ? d.location.coordinates[0] : d.longitude
    let lat = d?.location?.coordinates?.length ? d.location.coordinates[1] : d.latitude
    let newCenter = {
      lat: (lat || defaultCenter.lat) - offset,
      lng: lng || defaultCenter.lng
    }
    setCenter(newCenter)
    getDetailsAboutThisSite(d)
    if (selectedCrew?._id) {
      console.log('We already have a crew chosen so lets add this to their route')

      getRouteDataWithThisSite(filteredMarkers, newActiveMarker, 876)
    }
    setState(prevState => ({ ...prevState, assigningToService: false }))
  }

  const getDetailsAboutThisSite = async (site) => {
    // console.log('Get the closest jobsite and shit like that from the backend for this guy', site)
    const siteDetails = await axios.post(`${props.url}/api/hmi/getNearestSiteDetails`, site)
    console.log('Got the site details', siteDetails)
    // Lets merge the distance w/ the active sites
    let nearestSites = []
    let data = {
      ...siteDetails.data
    }
    if (siteDetails?.data?.nearestSites?.length) {
      let filteredSites = siteDetails.data.nearestSites.filter(item => (item._id !== site._id))
      // console.log('Sites that are nearby', filteredSites)
      let markersWithDistance = []
      let reocurringSites = []
      filteredSites.forEach((siteItem, index) => {
      
        let markerIndex = sites.findIndex(markerItem => (markerItem._id === siteItem._id))
        if (markerIndex > -1) {
          // console.log('Merge this shit', sites[markerIndex])
          // console.log('Site', siteItem)
          let color = getColor(sites[markerIndex].crew, crews)
          // console.log('Site Color:', siteItem.name, color)
          let itemToReturn = {
            ...sites[markerIndex],
            distance: formatDistance(siteItem.distance),
            color: color
          }
          markersWithDistance.push(itemToReturn)
          if (siteItem.frequency !== 'Monthly' && siteItem.frequency !== 'Quarterly' && siteItem.frequency !== 'Semi-Annual') reocurringSites.push(itemToReturn)
        }
      })
      // console.log('Markers w/ Distance', markersWithDistance)
      // console.log('Reocurring Sites', reocurringSites)
      data.nearestSites = markersWithDistance
      // filterTheMarkers(markersWithDistance, selectedDate, crews);
      // setFilteredMarkers(markersWithDistance)
      // let currentFilteredMarkers = filteredMarkers.map(item => {
      //   let markerWithDistance = markersWithDistance.find(markerItem => (markerItem?._id === item._id))
      //   if (markerWithDistance?._id) {
      //     return ({
      //       ...item,
      //       distance: markerWithDistance.distance
      //     })
      //   }
      // })
      // console.log('Filtered markers w/ Distance...', currentFilteredMarkers)
      // // setFilteredMarkers(currentFilteredMarkers)
      // // setAllFilteredMarkers(markersWithDistance);
    }
      console.log('Get suggested dates based on this shit....', data)
      if (data?.lastWorkorder?.scheduledDate) {
        const lastDate = new Date(data?.lastWorkorder?.scheduledDate)
        // let weekOfTheYear = moment(lastDate).week()
        let evenWeek = isEven(lastDate)
        let thisWeek = evenWeek ? 'Even' : 'Odd'
        const dayOfTheWeek = moment(lastDate).format('dddd')
        console.log('Last Date: %s   Frequency: %s   Day: %s    Even/Odd: %s  ', lastDate, site.frequency, dayOfTheWeek, thisWeek)
        const dates = findNextAppropriateDate(lastDate, site.frequency, dayOfTheWeek, thisWeek);
        // console.log('Suggested Dates:', dates)
        data.nextSuggestedDate = dates
      } else {
        data.nextSuggestedDate = null
      }

  
    // console.log('Set the data', data)
    setJobSiteDetails(data || {})
  }

  const handleClickOnCrew = (crew, updateRouteDetails) => {
    console.log('Click on crew', crew)
    if (selectedCrew && selectedCrew._id === crew._id) {
      // If the clicked crew is already selected, deselect it
      // setSelectedCrew(null);
      // // Reset to show all filtered markers
      // filterTheMarkers(jobSites, selectedDate, crews);
    } else {
      setSelectedCrew(crew);
      // Only display markers associated with this crew for the current date
      const crewSites = filterSitesForCrewAndDate(sites, selectedDate, crew._id);
      console.log('923 filtered crew sites', crewSites)
      // setFilteredMarkers(crewSites);
      filterMarkers(crewSites, selectedDate, crews, weeklyRoutes, false, crew);
    }

  }

  const filterSitesForCrewAndDate = (passedSites, date, crewId, routesPassed) => {
    console.log('FIltere these sites for crew', date)
    let markersToReturn = []
    const crew = [...crews].find(crewItem => (crewItem._id === crewId))
    if (!crew) {
      console.log('FAILED ', crewId, crews)
      throw new Error('Cannot find matching crew')
    }
    console.log('956 filter some shit!!!')
    // console.log(crew.name, date)
    const dayOfWeek = moment(date).format('dddd');
    console.log('Filter markers', dayOfWeek)
    let routesToUse = routesPassed ? routesPassed : weeklyRoutes?.length ? weeklyRoutes : []
    console.log('Routes to use 961', weeklyRoutes)
    const routesForDay = routesToUse.find(route => route.day === dayOfWeek);
    console.log('Routes for this day 963', routesForDay)
    if (routesForDay) {
      const routesToIterate = routesForDay.routes
      // console.log('Routes to use', routesToUse)
      const crewRouteForToday = routesToIterate.find(rte => (rte.crewId === crewId))
      console.log('Crew Route for today', crewRouteForToday)
      if (crewRouteForToday) {
        const markersForDay = crewRouteForToday?.stops.flatMap(stop => {
          // console.log('STop', stop)
          const siteToUse = passedSites.find(siteItem => (siteItem._id === stop.siteId))
          if (!siteToUse) {
            console.log('No site to use for this one....')
          }
          // console.log('Here it is:', siteToUse)
          return {
            ...siteToUse,
            ...stop,
            color: crew.color
          }
        });
        console.log('Markers for today 852', markersForDay)
        // setFilteredMarkers(markersForDay);
        markersToReturn = [...markersForDay]
      } else {
        console.log('No Crew route for today....')
      }
    } else {
      // setFilteredMarkers([]);
    }
    // console.log('Here are the sites for this crew and day  861', markersToReturn)
    return markersToReturn
  };


  const handleClickOnDayForCrew = (crew, day, e) => {
    console.log('Click on Day', day)
    e.stopPropagation()
    console.log(crew)
    // Calculate the date for the clicked day
    const clickedDate = moment(selectedDate).day(day);
    // Update the selected date
    const date = clickedDate.toDate()
    setSelectedDate(date);

    // Update the selected crew
    setSelectedCrew(crew);
    fetchRoutes(date, crew);
    // Filter markers for this crew and day
    // const filteredSitesForCrewAndDay = filterSitesForCrewAndDate(sites, clickedDate, crew._id);
    // console.log('1004 filtered sites', filteredSitesForCrewAndDay)
    // // // Update the filtered markers
    // setFilteredMarkers(filteredSitesForCrewAndDay);
    

  }

  const handleUnassignment = (site) => {
    console.log('Unassign this site...', site)
    setState(prevState => ({...prevState, activeJob: site }))
    if (site.jobType === 'regular') {
      setIsUnassignmentModalOpen(true)
    } else {
      console.log('Unassign a non-regular jobsite...')
      handleUnassignJob(false, site)
    }

  }

  const handleUnassignFromCrew = async (site, crew, date) => {
    console.log('Unassign from crew', { site, crew, date });
    
    const obj = {
      siteId: site._id,
      crewId: crew._id,
      date: date.toISOString(),
    };
    
    console.log('Sending unassignment request', obj);
    
    try {

      const unassignmentResponse = await axios.post(`${props.url}/api/hmi/genericPostRequest?type=unassignSiteFromCrew`, obj);
      console.log('Unassignment Response', unassignmentResponse);
      if (unassignmentResponse?.data?.success) {
      // if (unassignmentResponse?.data?.message) {
        const msg = unassignmentResponse.data.message;
        props.notification({
          type: 'success',
          message: msg,
          title: 'Unassignment Successful'
        });
        
        // Update weekly routes
        setWeeklyRoutes(prevWeeklyRoutes => {
          return prevWeeklyRoutes.map(route => {
            if (route.day !== moment(date).format('dddd')) {
              return route; // Return unchanged for other days
            }
            
            // Update the specific day
            return {
              ...route,
              routes: route.routes.map(crewRoute => {
                if (crewRoute.crewId !== crew._id) {
                  return crewRoute; // Return unchanged for other crews
                }
                
                // Remove the site from this crew's stops
                const updatedStops = crewRoute.stops.filter(stop => stop.siteId !== site._id);
                const removedStop = crewRoute.stops.find(stop => stop.siteId === site._id);
                
                // Recalculate route statistics
                const updatedServiceTime = crewRoute.totalServiceTime - (site.estimatedHours * 60);
                const updatedNumberOfStops = crewRoute.numberOfStops - 1;
                
                // Note: Recalculating totalDriveTime and totalMilesDriven accurately would require
                // re-optimizing the route, which might be too complex to do here.
                // For now, we'll just subtract the removed stop's drive duration as an approximation.
                const updatedDriveTime = crewRoute.totalDriveTime - (removedStop?.estimatedDriveDuration || 0);
                
                return {
                  ...crewRoute,
                  stops: updatedStops,
                  numberOfStops: updatedNumberOfStops,
                  totalServiceTime: updatedServiceTime,
                  totalDriveTime: updatedDriveTime,
                  // You may want to update totalMilesDriven here as well if you have that information
                };
              })
            };
          });
        });
        
        // Add the site back to unassigned jobs
        setUnassignedJobs(prevJobs => [...prevJobs, site]);
        
        // Clear any active markers or states
        setActiveMarker({});
        setState(prevState => ({ ...prevState, assigningToService: null }));
      }
    } catch (error) {
      console.error('Error unassigning site:', error);
      props.notification({
        type: 'error',
        message: 'Failed to unassign site. Please try again.',
        title: 'Unassignment Error'
      });
    }
  };

  const handleAssignToCrew = async (data) => {
    console.log('Assign to crew', data);
    const obj = {
      siteId: data?.site?._id,
      crewId: data?.crew?._id,
      scheduledDates: data.scheduledDates,
      selectedDate
    };
    console.log('send this', obj);
    let msg = `${Object.keys(data?.scheduledDates).length} Dates Scheduled In the Future for ${data?.crew?.name}`;
    

    const assignmentResponse = await axios.post(`${props.url}/api/hmi/genericPostRequest?type=assignSiteToCrew`, obj);
    console.log('Assignment Response', assignmentResponse);
    if (assignmentResponse?.data?.message) {
      msg = assignmentResponse?.data?.message;
        props.notification({
          type: 'success',
          message: msg,
          title: 'Assignment Updated'
        });
    }
    if (assignmentResponse && assignmentResponse.data) {
    // props.notification({
    //   type: 'success',
    //   message: msg,
    //   title: 'Assignment Updated'
    // });
  
    // Remove site from unassigned Jobs
    setUnassignedJobs(prevJobs => prevJobs.filter(site => site._id !== data.site._id));
  
    // Update weekly routes
    const updateWeeklyRoutes = (dayOfWeek) => {
      setWeeklyRoutes(prevWeeklyRoutes => {
        return prevWeeklyRoutes.map(route => {
          if (route.day !== dayOfWeek) {
            return route; // Return unchanged for other days
          }
  
          // Update the specific day
          return {
            ...route,
            routes: route.routes.map(crewRoute => {
              if (crewRoute.crewId !== data.crew._id) {
                return crewRoute; // Return unchanged for other crews
              }
  
              // Get the first scheduled date (assuming it's for today)
              const todaySchedule = Object.values(data.scheduledDates)[0];
              const estimatedHours = data.site.estimatedHours || 0;
  
              // Update the specific crew's route
              return {
                ...crewRoute,
                ...activeRouteDetails,
                stops: [
                  ...crewRoute.stops,
                  {
                    siteId: data.site._id,
                    estimatedArrivalTime: activeRouteDetails.estimatedArrivalTime,
                    estimatedDepartureTime: activeRouteDetails.estimatedDepartureTime,
                    estimatedDriveDuration: activeRouteDetails.estimatedDriveDuration,
                    estimatedHours: estimatedHours,
                    name: data.site.name,
                    address: data.site.address,
                    latitude: data.site.latitude,
                    longitude: data.site.longitude,
                    // Add any other fields you need for a stop
                  }
                ],
                numberOfStops: crewRoute.numberOfStops + 1,
                totalServiceTime: crewRoute.totalServiceTime + (estimatedHours * 60), // Convert hours to minutes
                totalDriveTime: activeRouteDetails.totalDriveTime,
                totalMilesDriven: activeRouteDetails.totalMilesDriven,
                // Update any other fields that need to be recalculated
              };
            })
          };
        });
      });
    };
    
      updateWeeklyRoutes(dayOfWeek);
    
      setActiveMarker({});
      setState(prevState => ({ ...prevState, assigningToService: null }));
    } else {
      console.log('Issue...1047')
    }
  };

  const handleClickOnScheduledSite = (d) => {
    console.log('Highlight this site:', d)
    setHighlightedSite(d._id)
  }

  const handlePreviousDay = () => {
    const yesterday = subDays(selectedDate, 1);
    handleDateChange(yesterday);
  }

  const handleNextDay = () => {
    const tomorrow = addDays(selectedDate, 1);
    handleDateChange(tomorrow);
  }

  const clearFilters = () => {
    console.log('Clear Filteres', crews.length)
    console.log('sites', sites.length)
    setSelectedCrew({});
    setHighlightedSite('')
    setHighlightedNearbySite('')
    filterMarkers(sites, selectedDate, crews, weeklyRoutes, true);
  }

  const handleClickOnSiteNearby = async (site) => {
    console.log('Click On this nearby site...', site);
    setLoading(true)
    setHighlightedNearbySite(site._id);
    setHighlightedSite(site._id);
    const nextDate = findNextMatchingDate(site);
    console.log('Next matching date:', nextDate);
    const date = new Date(nextDate);
    console.log('Date object', date);  
    const crew = filteredCrews.find(item => item._id === site.crew);
    console.log('Set this crew', crew);
    setSelectedCrew(crew);
    setSelectedDate(date);    
    // Wait for the state updates to complete before filtering markers
    // await new Promise(resolve => setTimeout(resolve, 0));
    fetchRoutes(date, crew);
  };

  const toggleCrewDetails = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCloseUnassignmentModal = () => {
    setIsUnassignmentModalOpen(false)
  }

  const handleUnassignJob = (cancelRecurring, site) => {
    setIsUnassignmentModalOpen(false)
    console.log('Unassign this:', cancelRecurring)
    console.log('state.activeJob', state.activeJob)
    const siteToUse = site ? site : state.activeJob
    let message = `Are you sure you wish to un-assign this one visit to ${siteToUse.name}?`
    if (cancelRecurring) {
      message = `Are you sure you wish to un-assign all future visits to ${siteToUse.name}?`
    }
    if (window.confirm(message)) {
      console.log('Unassign it...')
      handleUnassignFromCrew(siteToUse, selectedCrew, selectedDate)
    }
  }

  const handleChangeCrew = (direction) => {
    // console.log('Handle %s crew:', direction)
    // console.log('crews', crews)
    // console.log('Filtered crews', filteredCrews)
    const crew = selectedCrew
    // console.log('Crew', crew)
    let currentIndex = crews.findIndex(crewItem => (crewItem._id === crew._id))
    let newIndex = 0
    if (direction === 'prev') {
      currentIndex = currentIndex - 1
    } else {
      currentIndex++
    }
    // console.log('Current index', currentIndex)
    newIndex = currentIndex
    if (newIndex < 0) newIndex = crews.length - 1
    if (newIndex >= crews.length) newIndex = 0
    // console.log('New index', newIndex)
    const newCrew = crews[newIndex]
    // console.log('NExt crew ', newCrew)
    if (newCrew?._id) handleClickOnCrew(newCrew, true)
  }

  const handleDateClick = (d) => {
    console.log('User clicked this date:', d)
    setSelectedDate(d)
    fetchRoutes(d)
  }
    
  const handleSortClick = (e) => {
    setAnchorEl(e.currentTarget);
  }
  const handleSortChange = (newMethod) => {
    if (newMethod === sortMethod) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortMethod(newMethod);
      setSortDirection('asc');
    }
  };

  const handleDirectionChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  let filteredHours = 0
  let filteredStopsQty = 0
  filteredMarkers.forEach(item => {
    // console.log('Filter hours...', item)
    filteredHours += Number(item?.estimatedHours || 0)
    filteredStopsQty++
    // console.log(filteredHours)
  })
  // console.log('Filtered Hours', filteredHours)
  // if (activeMarker?._id) {
  //   filteredMarkers.push(activeMarker)
  // }
  console.log('active marker', activeMarker)
  // Color our Crews
  let crewLocsWithColor = []
  crewLocations.forEach(item => {
    let crewIndex = [...crews].findIndex(crew => (crew._id === item._id))
    if (crewIndex > -1) {
      const crewItem = crews[crewIndex]
      // console.log('CREW: 684', crewItem)
      let hexColor = crewItem.color
      let itemWithColor = {
        ...item,
        color: hexColor
      }
      crewLocsWithColor.push(itemWithColor)
    }
  })

  const dayOfTheWeek = moment(selectedDate).format('dddd')

  let crewRoute = null
  if (selectedCrew?._id && weeklyRoutes && dayOfWeek) {
    // let routeToUse = 
    // console.log('Set Crew route ', selectedCrew)
    // console.log(dayOfWeek)
    // console.log('Weekly routes', weeklyRoutes)
    let routeToday = weeklyRoutes.find(rt => (rt.day === dayOfWeek))
    if (routeToday) {
      // console.log('Got route today', routeToday)
      let routeForCrew = routeToday.routes.find(rt => (rt.crewId === selectedCrew._id))
      if (routeForCrew) crewRoute = routeForCrew
    }
  }
  // console.log('1009', state)
  // console.log('Crew Route', crewRoute)
  return (
    <div className={classes.root}>
      <UnassignmentConfirmationModal
       open={isUnassignmentModalOpen}
       onClose={handleCloseUnassignmentModal}
       onConfirmUnassignment={handleUnassignJob}
       activeSite={state.activeJob}
     />
      <Grid container>
        <Grid item xs={3}>
          <Paper className={classes.calendarPaper}>
            {/* <CalendarTabArea /> */}
            <Typography variant="h7">
              Total Properties: {sites.length}
              <br />
            </Typography>
            <div className={classes.dateArea} >
                <Button onClick={handlePreviousDay}>PREV</Button>
                <DatePicker
                  value={selectedDate}
                  onChange={handleDateChange}
                  animateYearScrolling
                />
                <Button onClick={handleNextDay}>NEXT</Button>
            </div>
            <Typography variant="h5">
              {dayOfTheWeek}
            </Typography>
            <Typography variant="h5">
             Week #{weekOfTheYear} - {thisWeek} - (Week of {moment(state.weekStart).format('MM/DD/YYYY')})
            </Typography>
            {/* <Typography variant="h6">
              Total Hours: {Math.round(100 * totalHours) / 100}
            </Typography> */}
            <Typography variant="h7">
              Filtered Stops: {filteredStopsQty}
              <br />
              Filtered Hours: {Math.round(100 * filteredHours) / 100}
            </Typography>
          </Paper>
          <Paper className={classes.crewPaper}>
            
            <div className={classes.crewList}>
                { filteredCrews.map((crew) => {

                  // console.log('Crew Item 1157', crew)
                  //   console.log('Hours details', weeklyHours[crew._id])
                    const todaysData = weeklyHours[crew._id][dayOfTheWeek]
                    // console.log('Todays data', todaysData)
                    const totalHours = Number(todaysData.serviceTime) + Number(todaysData.driveTime)
                    const hoursToday = Math.round(100 * totalHours) / 100
                    const weeklyTotals = weeklyHours[crew._id]['total']
                    // console.log('Weekly Totals', weeklyTotals)
                    const totalHoursForTheWeek = Number(weeklyTotals.serviceTime) + Number(weeklyTotals.driveTime)
                    const hoursThisWeek = Math.round(100 * totalHoursForTheWeek) / 100
                    return (
                  <div 
                    key={crew._id} 
                    style={{ 
                      borderRight: `12px solid ${crew.color}`, 
                      cursor: 'pointer', 
                      backgroundColor: '#fff',
                      padding: 10, 
                      margin: 5, 
                      borderRadius: 8,
                      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                    }} 
                    onClick={e => handleClickOnCrew(crew)}
                  >
                    <div className={classes.crewDetailsContainer}>
                      <div className={classes.crewDetailsTopLine}>
                    
                              <InfoItem 
                              label="Crew Name" 
                              value={`${crew.name}`}
                              
                            />
                              <InfoItem 
                              label="Hours Today" 
                              value={`${hoursToday}`} 
                              centerItem
                            />
                          <InfoItem 
                              label="Hours This Week" 
                              value={`${hoursThisWeek}`}
                              centerItem
                            />
                         
                      </div>
                      {/* <div className={classes.crewBlockColor} style={{ backgroundColor: crew?.color || '#fff' }} >&nbsp;</div> */}
                    
                      <WeeklyHoursDisplay 
                        weeklyHours={weeklyHours[crew._id]}
                        handleDayClick={(day, e) => handleClickOnDayForCrew(crew, day, e)}
                        selectedDay={selectedCrew && selectedCrew._id === crew._id ? moment(selectedDate).format('dddd') : null}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
          <div className={classes.mapContainer}>
            <div className={classes.map}>
              {
                isLoaded ? (
                  <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={center}
                      zoom={zoomLevel}
                      onLoad={onLoad}
                      // onUnmount={onUnmount}
                      
                      options={{ 
                        gestureHandling: "greedy",
                        zoomControl: true,
                        zoomControlOptions: {
                          position: window.google.maps.ControlPosition.LEFT_TOP,
                        },
                      
                      }}
                    >
                   { !loading && filteredMarkers.map((marker, index) => (
                      <CustomMarker
                        key={index}
                        position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
                        icon={marker.jobType}
                        highlightedSite={highlightedSite}
                        color={marker.color}
                        jobsite={marker}
                        activeMarker={activeMarker}
                        updateActiveMarker={updateActiveMarker}
                        hideInfoWindowOnActiveItem={true}
                      />
                    ))}
                    {
                      activeMarker?._id && <Marker
                              key={activeMarker?._id}
                              // icon={theIcon}
                              position={{ lat: activeMarker?.latitude, lng: activeMarker?.longitude }}
                              title={activeMarker?.name}
                              // color={crewMarker.color}
                            />
                        
                      
                    }
                      {
                        (!loading && !activeMarker?._id) && crewLocsWithColor.map((crewMarker, index) => {
                          console.log('Display Crew', crewMarker)
                          let latitude = crewMarker?.lastLocation?.latitude
                          let longitude = crewMarker?.lastLocation?.longitude
                          let theIcon = {
                            path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            // url: 
                            scale: 3,
                            fillColor: crewMarker.color,
                            fillOpacity: 0.8,
                            strokeWeight: 2,
                            rotation: 0,
                            strokeColor: crewMarker.color
                        }
                          return (
                            <Marker
                                key={index}
                                icon={theIcon}
                                position={{ lat: latitude, lng: longitude }}
                                title={crewMarker.name}
                                color={crewMarker.color}
                              />
                          )
                          }
                        )
                      }
                    </GoogleMap>
                ) : <></>
              }

            </div>
            {selectedCrew?._id && (
               <div 
               className={classes.selectedCrewContainer} 
             >
                            
                  <SelectedCrewDetails
                    crew={selectedCrew}
                    crews={crews}
                    date={selectedDate} 
                    stops={filteredMarkers}
                    crewRoute={crewRoute}
                    activeMarker={activeMarker} 
                    jobSiteDetails={jobSiteDetails} 
                    handleUnassignment={handleUnassignment} 
                    handleAssignToCrew={handleAssignToCrew}
                    markers={sites}
                    activeRouteDetails={activeRouteDetails}
                    highlightedSite={highlightedSite}
                    handleClickOnScheduledSite={handleClickOnScheduledSite}
                    weeklyRoutes={weeklyRoutes}
                    loading={loading}
                    handleChangeCrew={handleChangeCrew}
                  />
                </div>
              )}
              <div className={classes.mapOverlayActionArea}>
                {
                  selectedCrew?._id && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={clearFilters}
                    >Show All Crews</Button>
                  )
                }
                {
                  state.assigningToService && (
                    <div style={{ marginTop: 5, backgroundColor: '#fff', padding: 10, borderRadius: 5, border: '2px solid #ccc' }}>
                      Assigning to {state.assigningToService?.name} {state.assigningToService?.year} Visit
                    </div>
                  )
                }
              </div>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <JobsHeader
            tabValue={tabValue}
            setTabValue={setTabValue}
            setActiveMarker={setActiveMarker}
            setState={setState}
            filterMarkers={filterMarkers}
            handleClickAssign={handleClickAssign}
            unassignedJobs={unassignedJobs}
            sites={sites}
            crews={crews}
            weeklyRoutes={weeklyRoutes}
            selectedCrew={selectedCrew}
            selectedDate={selectedDate}
            jobSiteDetails={jobSiteDetails}
            handleDateClick={handleDateClick}
            filteredCrews={filteredCrews}
            handleClickOnSiteNearby={handleClickOnSiteNearby}
            highlightedNearbySite={highlightedNearbySite}
            activeMarker={activeMarker}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const UnassignedJobsDetails = (
  { activeMarker, 
    sites, crews,
    weeklyRoutes, 
    selectedCrew,
     setActiveMarker, 
    setState, 
    filterMarkers, 
    handleClickAssign, 
    unassignedJobs,
    jobSiteDetails,
    filteredCrews,
    handleClickOnSiteNearby,
    highlightedNearbySite,
    selectedDate,
    handleDateClick,
  }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.unassignedJobsPaper}>
    <div className={classes.unassignedJobsHeader}>
      <div>
        <Typography variant="h6">Unassigned Jobs {unassignedJobs.length} </Typography>
      </div>
      <div>
        {
          activeMarker?._id && (
            <Button 
                onClick={() => {
                  setActiveMarker({})
                  setState(prevState => ({ ...prevState, assigningToService: null }))
                  // setFilteredMarkers(allFilteredMarkers)
                  filterMarkers(sites, selectedDate, crews, weeklyRoutes, false, selectedCrew);
                  // filterTheMarkers(markers, selectedDate, crews)
                }}
                startIcon={<FormatListBulletedIcon />}
                variant="contained"
                color="primary"
                style={{ margin: '10px 0' }}
              >
                Show All
              </Button>
          )
        }
      </div>

    </div>
    
    {
      activeMarker?._id ? (
        <div>
          <div>
            <ActiveMarkerDetails activeMarker={activeMarker} jobSiteDetails={jobSiteDetails} clickOnDate={handleDateClick} />                  
          </div>
          <div style={{ overflowY: 'auto', margin: 5, padding: 5 }}>
              <ClosestLocationDetails jobSiteDetails={jobSiteDetails} site={activeMarker} crews={filteredCrews} handleClickOnSiteNearby={handleClickOnSiteNearby} highlightedNearbySite={highlightedNearbySite} />
          </div>
          
        </div>
      ) : (
        <List>
        {unassignedJobs.map((job) => {
          let isActiveJob = activeMarker?._id === job._id
          return (
          <ListItem key={job._id} className={isActiveJob ? classes.activeJobItem : classes.unassignedJobItem}>
            <ListItemText
              primary={job.name}
              secondary={`Frequency: ${job.frequency}`}
            />
            <ListItemText
              primary={`Hours: ${job?.estimatedHours || 0} `}
            />
            <Button variant="contained" color="primary" onClick={ e => handleClickAssign(job) }>
              Assign
            </Button>
          </ListItem>
        )
      })}
      </List>
      )
    }

  </Paper>
  )
}
const ActiveMarkerDetails = ({ activeMarker, jobSiteDetails, clickOnDate }) => {
  const classes = useStyles();
  console.log('Jobsite details:', jobSiteDetails)
  const nextSuggestedDate = jobSiteDetails?.nextSuggestedDate ? moment(jobSiteDetails.nextSuggestedDate).format('ddd MM/DD/YY') : null
  console.log('Next Suggested Date', nextSuggestedDate)
  const lastServiceDate = (jobSiteDetails?.lastWorkorder && jobSiteDetails?.lastWorkorder?.scheduledDate) ? moment(jobSiteDetails?.lastWorkorder?.scheduledDate).format('ddd MM/DD/YY') : null
  const addy = `${activeMarker?.communityName ? activeMarker.communityName : ''} ${ activeMarker?.address } ${activeMarker?.buildingNumber ? `(Bldg #${activeMarker.buildingNumber})` : ''} ${activeMarker?.city} `
  
  const handleSuggestedDateClick = () => {
    // console.log('Click on suggested date')
    clickOnDate(jobSiteDetails.nextSuggestedDate)
  }
  
  return (
    <Paper className={classes.activeMarkerContainer}>
      
        <div className={classes.flexRow}>
          <InfoItem 
            label="Site Name" 
            value= { activeMarker?.name }
            centerItem
          />
          <InfoItem 
            label="Site Address" 
            value= { addy }
            centerItem
          />
        </div>
        <div className={classes.flexRow}>
            <InfoItem 
                label="Frequency" 
                centerItem
                value={`${activeMarker?.frequency} ${activeMarker?.frequency === 'Bi-Weekly' ? `(${activeMarker.evenOrOddWeeks})` : ''}`} 
              />
               <InfoItem 
                label="Preferred Day"
                centerItem 
                value={`${activeMarker?.preferredDay}`} 
              />
                <InfoItem 
                label="Estimated hours" 
                value={`${activeMarker?.estimatedHours || ''}`} 
                centerItem
              />
               {/* <InfoItem 
                label="# of Services to Schedule" 
                value={`${jobSiteDetails?.totalServicesRemaining || ''}`} 
                centerItem
              /> */}
               <InfoItem 
                label="Last Service Date"
                centerItem
                value={`${ lastServiceDate || ''}`} 
              />
        </div>
        <div className={classes.flexRowCentered}>
          {
            nextSuggestedDate && <InfoItem 
              label="Suggested Next Service Date"
              centerItem
              value={`${ nextSuggestedDate || ''}`} 
              clickable={handleSuggestedDateClick}
            />
          }
        </div>
    </Paper>
  )
}

const ClosestLocationDetails = (props) => {
  console.log('Nearest site details', props)
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  // console.log('Closest Location Details', props)
  const longtermFrequencies = [
    'Monthly',
    'Quarterly',
    'Semi-Annually',
    'Annual',
    'On Demand',
    'On-Demand'
  ]
  const {
    nearestSites = [],
    lastWorkorder,
  } = props?.jobSiteDetails
  if (!nearestSites?.length) return null

  const site = props.site

  let filteredSites = nearestSites.filter(item => {
    let includeThis = false
    if (!item.crew || item?.crewName === 'Unassigned') return false
    if (item._id !== site._id) {
      // console.log('Not our active marker...', item)
      // console.log('Site.frequency', site.frequency)
      const isNotRegular = longtermFrequencies.includes(site.frequency)
      const itemIsNotRegular = longtermFrequencies.includes(item.frequency)
      // console.log('Site is not regular:  %s   Item is Not Regular: %s ', isNotRegular, itemIsNotRegular)
      if (!longtermFrequencies.includes(site.frequency)) {
        // Short term frequency - dont show our longterms
        includeThis = !longtermFrequencies.includes(item.frequency)
        // console.log('Do we include this thing???', includeThis, item.frequency)
      } else {
        // console.log('Include this thing....', site.frequency)
        includeThis = true
      }
      // if (site.frequency === item.frequency) includeThis = true
    }
    // if (includeThis) {
    //   let crew = props?.crews.find(crewItem => (crewItem._id === site.crew))
    //   if (crew?._id) site.crewName = `${crew.name} ${crew.foremanName ? `(${crew.foremanName})` : ''}`
    // }
    return includeThis
  }).slice(0, 10)
  
  console.log('1379 filtered sites', filteredSites)
  let closestMatchSites = nearestSites.filter(item => {
    if (!item.crew || item?.crewName === 'Unassigned') return false
    let includeThis = false
    if (item._id !== site._id) {
      if (site.frequency === item.frequency) includeThis = true
    }
    return includeThis
  }).slice(0, 10)

  let perfectMatches = nearestSites.filter(item => {
    if (!item.crew || item?.crewName === 'Unassigned') return false
    let includeThis = false
    // console.log('Seek Perfect Matches', site)
    if (item._id !== site._id && item?.crewName !== 'Unassigned' ) {
      // console.log('This one might work', item)
      if (site.frequency === item.frequency) {
        // console.log('Frequency Match')
        if (site.preferredDay === item.preferredDay) {
          // console.log('Days Match')
          if (site.frequency === 'Bi-Weekly') {
            if (site.evenOrOddWeeks === item.evenOrOddWeeks) {
              // console.log('Even or odd matches')
              includeThis = true
            }
          } else {
            includeThis = true
          }
        }
      }
    }
    return includeThis
  }).slice(0, 10)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // console.log('Perfect Matches', perfectMatches)
  return (
    <div className={classes.tabsRoot}>
      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="closest sites tabs"
        >
          <Tab label="Closest Sites" />
          <Tab label="Closest Matching Sites" />
          <Tab label="Perfect Matches" />
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <Typography variant="h6" gutterBottom>Nearest Locations:</Typography>
        <SiteList 
          nearestSites={filteredSites} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby}
          highlightedNearbySite={props.highlightedNearbySite}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Typography variant="h6" gutterBottom>Nearest Matching Locations:</Typography>
        <SiteList 
          nearestSites={closestMatchSites} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby}
          highlightedNearbySite={props.highlightedNearbySite}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Typography variant="h6" gutterBottom>Perfect Matching Locations:</Typography>
        <SiteList 
          nearestSites={perfectMatches} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby}
          highlightedNearbySite={props.highlightedNearbySite}
        />
      </TabPanel>
    </div>
  )
}

const JobsHeader = ({ 
  tabValue,
  setTabValue,
  setActiveMarker,
  setState,
  filterMarkers,
  handleClickAssign,
  unassignedJobs,
  sites,
  crews,
  weeklyRoutes,
  selectedCrew,
  selectedDate,
  jobSiteDetails,
  handleDateClick,
  filteredCrews,
  handleClickOnSiteNearby,
  highlightedNearbySite,
  activeMarker
 }) => {
  const classes = useStyles();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <div className={classes.tabsRootSidebar}>
      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="closest sites tabs"
        >
          <Tab label="Unassigned Properties" />
          <Tab label="All Properties" />
        </Tabs>
      </Paper>
      <CustomTabPanel value={tabValue} index={0}>
        <div className={classes.paperRoot}>
          <UnassignedJobsDetails
            tabValue={tabValue}
            setTabValue={setTabValue}
            setActiveMarker={setActiveMarker}
            setState={setState}
            filterMarkers={filterMarkers}
            handleClickAssign={handleClickAssign}
            unassignedJobs={unassignedJobs}
            activeMarker={activeMarker}
            sites={sites}
            crews={crews}
            weeklyRoutes={weeklyRoutes}
            selectedCrew={selectedCrew}
            selectedDate={selectedDate}
            jobSiteDetails={jobSiteDetails}
            handleDateClick={handleDateClick}
            filteredCrews={filteredCrews}
            handleClickOnSiteNearby={handleClickOnSiteNearby}
            highlightedNearbySite={highlightedNearbySite}
          />
        </div>

      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <Paper className={classes.paperRoot}>
          <Typography variant="h6" gutterBottom>All Properties:</Typography>
        </Paper>
       
       
      </CustomTabPanel>
    </div>
  )
}

const InfoItem = ({ label, value, centerItem, clickable }) => {
  const classes = useStyles();
  if (clickable) {
    return (
      <div className={classes.infoItemClickable} onClick={clickable}>
        <Typography className={classes.infoValue} style={ centerItem ? { textAlign: 'center' } : null }>{value}</Typography>
        <Typography className={classes.infoLabel} style={ centerItem ? { textAlign: 'center' } : null }>{label}</Typography>
      </div>
    );
  } else {
    return (
      <div className={classes.infoItem} >
        <Typography className={classes.infoValue} style={ centerItem ? { textAlign: 'center' } : null }>{value}</Typography>
        <Typography className={classes.infoLabel} style={ centerItem ? { textAlign: 'center' } : null }>{label}</Typography>
      </div>
    );
  }

};

const SiteList = ({ nearestSites, handleClickOnSiteNearby, closestMatchSites,  highlightedNearbySite }) => {
  const classes = useStyles();
  // console.log('HhighlightedNearbySite', highlightedNearbySite)
  const dayOrder = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


  return (
    <div className={classes.siteListContainer}>
      {nearestSites.map((site, index) => {
        // console.log("Nearby site", site)
        const sortedPreferredDays = site?.preferredDays?.sort((a, b) => {
          return dayOrder.indexOf(a) - dayOrder.indexOf(b);
        }) || []
        const preferredDaysDisplay = sortedPreferredDays.join('/') || ''
        let extraStyle = {         
          backgroundColor: 'inherit'
        }
        if (highlightedNearbySite === site._id) {
          extraStyle = {
            // backgroundColor: '#eee',
            border: `2px solid ${site.color}`
          }
        }
        return (
        <div key={index} className={classes.nearestSiteItemContainer} style={extraStyle} onClick={() => handleClickOnSiteNearby(site)}>
          <div className={classes.colorBar} style={{ backgroundColor: site.color }} />
          <div className={classes.contentContainer}>
            <div className={`${classes.column} ${classes.smallColumn}`}>
              <InfoItem 
                label="Distance" 
                value={`${Math.round(100 * site?.distance) / 100} mi`}
              />
              <InfoItem 
                label="Estimated Hours" 
                value={Math.round(100 * site?.estimatedHours) / 100} 
              />
             
            </div>
            <div className={`${classes.column} ${classes.largeColumn}`}>
              <InfoItem 
                label="Name" 
                value={site?.name} 
              />
              <InfoItem 
                label="Frequency" 
                value={`${site?.frequency} ${site?.frequency === 'Bi-Weekly' ? `(${site.evenOrOddWeeks})` : ''}`} 
              />
            </div>
            <div className={`${classes.column} ${classes.largeColumn}`}>
              {
                site.frequency === 'Twice a Week' ? (
                  <InfoItem 
                  label="Preferred Days" 
                  value={preferredDaysDisplay} 
                />
                ) : (
                <InfoItem 
                  label="Preferred Day" 
                  value={site.preferredDay} 
                />
                )
              }
              <InfoItem 
                label="Assigned Crew" 
                value={site.crewName} 
              />
            </div>
          </div>
        </div>
      )})}
    </div>
  );
};

const WeeklyHoursDisplay = ({ weeklyHours, handleDayClick, selectedDay }) => {
  if (!weeklyHours) return null;

  const days = ['M', 'T', 'W', 'Th', 'F'];
  const fullDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #ccc', paddingTop: '10px', marginTop: '10px' }}>
      {days.map((day, index) => (
        <div key={day} 
          style={{
            textAlign: 'center', 
            flex: 1, 
            cursor: 'pointer',
            backgroundColor: fullDays[index] === selectedDay ? '#e0e0e0' : 'transparent',
            borderRadius: '4px',
            padding: '2px'
          }} 
          onClick={(e) => handleDayClick(fullDays[index], e)}>
          <div style={{ fontWeight: 'bold' }}>{day}</div>
          {/* <div>S: {weeklyHours[fullDays[index]].serviceTime.toFixed(1)}</div>
          <div>D: {weeklyHours[fullDays[index]].driveTime.toFixed(1)}</div> */}
          <div>{Math.round(100 * Number(weeklyHours[fullDays[index]].serviceTime + weeklyHours[fullDays[index]].driveTime)) / 100}</div>
        </div>
      ))}
    </div>
  );
};

const CustomTabPanel = ({ children, value, index, ...other }) => {
  const classes = useStyles();

  return (
    <TabPanel
      value={value}
      index={index}
      {...other}
      className={classes.tabPanel}
    >
      {children}
    </TabPanel>
  );
};


const getCrewColor = (index) => {
  return distinctHexColors[index % distinctHexColors.length];
};

const distinctHexColors = [    
  "#FF0000", // Red
  "#00FF00", // Lime
  "#0000FF", // Blue
  "#FFFF00", // Yellow
  "#FF00FF", // Magenta
  "#00FFFF", // Cyan
  "#FFA500", // Orange
  "#800080", // Purple
  "#008000", // Green
  "#FFC0CB", // Pink
  "#A52A2A", // Brown
  "#808080", // Gray
  // "#111", // Black
  "#FFFFFF", // White
  "#FFD700", // Gold
  "#4B0082", // Indigo
  "#7FFF00", // Chartreuse
  "#FF4500", // OrangeRed
  "#1E90FF", // DodgerBlue
  "#8B4513"  // SaddleBrown
]

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    crews: state.crews,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(SchedulerComponent)