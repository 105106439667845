import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  logoArea: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginBottom: 15,
  },
  headerArea: {
    marginTop: 25,
    textAlign: 'center',
    padding: 15,
    fontWeight: 500,
    color: '#888',
  },
  flowerCard: {
    maxWidth: 200,
    margin: 'auto',
  },
  media: {
    height: 140,
  },
}));

const flowerTypes = [
  {
    name: 'Geraniums',
    colors: ['red', 'white', 'pink'],
    image: 'https://example.com/geraniums.jpg',
  },
  {
    name: 'Petunias',
    colors: ['red', 'white', 'pink', 'blue', 'purple'],
    image: 'https://example.com/petunias.jpg',
  },
  {
    name: 'Cyclamen',
    colors: ['red', 'white', 'pink'],
    image: 'https://example.com/cyclamen.jpg',
  },
  {
    name: 'Pansies',
    colors: ['red', 'white', 'pink', 'blue', 'purple'],
    image: 'https://example.com/pansies.jpg',
  },
  {
    name: 'Snapdragons',
    colors: ['red', 'white', 'pink', 'blue', 'orange'],
    image: 'https://example.com/snapdragons.jpg',
  },
  {
    name: 'Trailing Bacopa',
    colors: ['blue', 'white'],
    image: 'https://example.com/trailing_bacopa.jpg',
  },
  {
    name: 'Trailing Alyssum',
    colors: ['white'],
    image: 'https://example.com/trailing_alyssum.jpg',
  },
  {
    name: 'Lobelia',
    colors: ['blue'],
    image: 'https://example.com/lobelia.jpg',
  },
  {
    name: 'Kalanchoe',
    colors: ['red', 'orange', 'white', 'yellow'],
    image: 'https://example.com/kalanchoe.jpg',
  },
];

const WinterFlowersSelection = () => {
  const classes = useStyles();
  const [wantFlowers, setWantFlowers] = useState(false);
  const [selectedFlowers, setSelectedFlowers] = useState([]);
  const [sameAsLastYear, setSameAsLastYear] = useState(false);

  const handleFlowerSelect = (flowerName) => {
    setSelectedFlowers((prev) =>
      prev.includes(flowerName)
        ? prev.filter((name) => name !== flowerName)
        : [...prev, flowerName]
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log({
      wantFlowers,
      selectedFlowers,
      sameAsLastYear,
    });
    alert('Selection submitted!');
  };

  const handleScheduleMeeting = () => {
    // Implement scheduling logic here
    alert('Redirecting to schedule a meeting...');
  };

  const handleRequestQuote = () => {
    // Implement request quote logic here
    alert('Redirecting to request a quote...');
  };

  let logoURL = 'https://dflportal.com/build/logowide2.png';

  return (
    <Paper style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <div className={classes.logoArea}>
        <img
          alt="Logo"
          src={`${logoURL}`}
          style={{
            marginLeft: '-100px',
            marginTop: '5px',
            maxHeight: 100,
            maxWidth: 300,
          }}
        />
      </div>
      <Typography variant="h4" className={classes.headerArea} gutterBottom>
        Winter Flowers Selection
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={wantFlowers}
                onChange={(e) => setWantFlowers(e.target.checked)}
                color="primary"
              />
            }
            label="I want flowers this winter"
          />
        </FormGroup>

        {wantFlowers && !sameAsLastYear && (
          <FormControl
            component="fieldset"
            style={{ marginTop: '20px' }}
            fullWidth
          >
            <FormLabel component="legend">Select Your Flowers</FormLabel>
            <Grid container spacing={3}>
              {flowerTypes.map((flower) => (
                <Grid item xs={12} sm={6} md={4} key={flower.name}>
                  <Card className={classes.flowerCard}>
                    <CardMedia
                      className={classes.media}
                      image={flower.image}
                      title={flower.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h2">
                        {flower.name}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Available colors: {flower.colors.join(', ')}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFlowers.includes(flower.name)}
                            onChange={() => handleFlowerSelect(flower.name)}
                            color="primary"
                          />
                        }
                        label="Select"
                      />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </FormControl>
        )}

        <FormGroup style={{ marginTop: '20px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sameAsLastYear}
                onChange={(e) => {
                  setSameAsLastYear(e.target.checked);
                  if (e.target.checked) {
                    setSelectedFlowers([]);
                  }
                }}
                color="primary"
              />
            }
            label="Same as Last Year"
          />
        </FormGroup>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '20px', marginRight: '10px' }}
          disabled={!wantFlowers && !sameAsLastYear}
        >
          Submit Selection
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          style={{ marginTop: '20px', marginRight: '10px' }}
          onClick={handleScheduleMeeting}
        >
          Schedule a Meeting
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          style={{ marginTop: '20px' }}
          onClick={handleRequestQuote}
        >
          Request a Quote
        </Button>
      </form>
    </Paper>
  );
};

export default WinterFlowersSelection;
