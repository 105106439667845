exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".profileModify_profileModify__3leJ8 {\n  margin: 20px;\n  text-align: left; }\n\n.profileModify_title__25MgY {\n  padding-left: 30px;\n  padding-top: 20px; }\n\n.profileModify_content__3Ge-z {\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0; }\n\n.profileModify_link__-lwzt {\n  margin-left: 20px;\n  margin-right: 20px;\n  text-decoration: none;\n  color: white;\n  cursor: pointer; }\n\n.profileModify_logo__QvH0H {\n  margin-left: 0;\n  margin-right: 0; }\n", ""]);

// Exports
exports.locals = {
	"profileModify": "profileModify_profileModify__3leJ8",
	"title": "profileModify_title__25MgY",
	"content": "profileModify_content__3Ge-z",
	"link": "profileModify_link__-lwzt",
	"logo": "profileModify_logo__QvH0H"
};