exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".recoveryHash_recoveryHash__1t6De {\n  margin: 20px;\n  text-align: left; }\n\n.recoveryHash_title__1iwmr {\n  padding-left: 30px;\n  padding-top: 20px; }\n\n.recoveryHash_content__l0tsc {\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0; }\n\n.recoveryHash_logo__3ol9d {\n  margin-left: 0;\n  margin-right: 0; }\n", ""]);

// Exports
exports.locals = {
	"recoveryHash": "recoveryHash_recoveryHash__1t6De",
	"title": "recoveryHash_title__1iwmr",
	"content": "recoveryHash_content__l0tsc",
	"logo": "recoveryHash_logo__3ol9d"
};