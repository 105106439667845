import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '90vw',
      maxWidth: '90vw',
    },
  },
  table: {
    marginTop: theme.spacing(2),
  },
  totalRow: {
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
  },
  qtyInput: {
    width: '60px',
  },
  typeSelect: {
    minWidth: '150px',
  },
  descriptionInput: {
    minWidth: '200px',
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
}));

const initialLineItem = {
  qty: '',
  type: '',
  description: '',
  costPer: '',
  pricePer: '',
};

const LineItemModal = ({ open, onClose, onSubmit, initialLineItems = [] }) => {
  console.log('66::: ', initialLineItems)
  const classes = useStyles();
  const [lineItems, setLineItems] = useState(initialLineItems);
  const [editingIndex, setEditingIndex] = useState(null);

  useEffect(() => {
    if (initialLineItems.length) setLineItems(initialLineItems)
  }, [initialLineItems])

  const handleInputChange = (index, field, value) => {
    setLineItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const handleAdd = () => {
    setLineItems((prev) => [...prev, { ...initialLineItem }]);
    setEditingIndex(lineItems.length);
  };

  const handleSave = (index) => {
    setEditingIndex(null);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
  };

  const handleDelete = (index) => {
    setLineItems((prev) => prev.filter((_, i) => i !== index));
  };

  const calculateTotal = (item) =>
    parseFloat(item.qty) * parseFloat(item.pricePer) || 0;

  const calculateGrandTotal = () =>
    lineItems.reduce((sum, item) => sum + calculateTotal(item), 0);

  const isItemComplete = (item) =>
    item.qty && item.type && item.description && item.costPer && item.pricePer;

  const handleSubmit = () => {
    onSubmit(lineItems);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth className={classes.dialog}>
      <DialogTitle>Line Item Builder</DialogTitle>
      <DialogContent>
        <TableContainer className={classes.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Quantity</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Cost Per</TableCell>
                <TableCell>Price Per</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lineItems.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      type="number"
                      value={item.qty}
                      onChange={(e) => handleInputChange(index, 'qty', e.target.value)}
                      disabled={editingIndex !== index}
                      className={classes.qtyInput}
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      value={item.type}
                      onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                      disabled={editingIndex !== index}
                      className={classes.typeSelect}
                    >
                      <MenuItem value="" disabled>Select Type</MenuItem>
                      {['Labor', 'Materials', 'Subcontractor', 'Equipment', 'Supervision'].map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      value={item.description}
                      onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                      disabled={editingIndex !== index}
                      className={classes.descriptionInput}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={item.costPer}
                      onChange={(e) => handleInputChange(index, 'costPer', e.target.value)}
                      disabled={editingIndex !== index}
                      className={classes.input}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={item.pricePer}
                      onChange={(e) => handleInputChange(index, 'pricePer', e.target.value)}
                      disabled={editingIndex !== index}
                      className={classes.input}
                    />
                  </TableCell>
                  <TableCell>
                    ${calculateTotal(item).toFixed(2)}
                  </TableCell>
                  <TableCell>
                    {editingIndex === index ? (
                      <IconButton onClick={() => handleSave(index)} disabled={!isItemComplete(item)}>
                        <SaveIcon />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleEdit(index)}>
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={() => handleDelete(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow className={classes.totalRow}>
                <TableCell colSpan={5} align="right">
                  Grand Total:
                </TableCell>
                <TableCell>${calculateGrandTotal().toFixed(2)}</TableCell>
                <TableCell />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAdd}
          startIcon={<AddIcon />}
          className={classes.addButton}
        >
          Add New Line Item
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LineItemModal;