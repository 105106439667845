import React, { useState, useRef } from 'react';
import { Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Paper, IconButton, Tooltip } from '@material-ui/core';
import { GoogleMap, LoadScript, DrawingManager, Polygon } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { connect } from 'react-redux'
import axios from 'axios'
import CustomMarker from './CustomMarker'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { useLocation } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PrintIcon from '@material-ui/icons/Print'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles';
import { isEven } from '../../services/common'
import EditJobSiteModal from './MaintenanceStuff/EditJobSiteModal'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

let weekOfTheYear = moment(new Date()).week()
let evenWeek = isEven(weekOfTheYear)
let weekType = evenWeek ? 'Even' : 'Odd'

const DefaultLineItemContainer = styled(TableBody)`
background-color: ${props => (props.isDraggingOver ? '#83a893' : 'inherit')};
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
min-height: 100px;
`;

const DefaultLineItemRow = styled(TableRow)`
  border: ${props => ((props.isClockedIn || props.isDragging) ? '3px solid #444' : '2px solid lightgrey')};
  color: ${props => ((props.absenceType || props.isCheckedIn || props.isClockedIn)  ? '#ddd;' : '#444;')};
  border-radius: 5px;
  padding: 4px;
  width: 100%;
  margin: 2px;
  &:hover {
    background-color: #f5f5f5;
  };
  background-color: ${props => (
    props.isDragging ? '#34eb80' : 'white' )};
`;

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '90vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  detailsContaner: {
    width: '100%',
    // backgroundColor: '#ddd'
  },
  crewDetailHeader: {
    textAlign: 'center',
    fontWeight: 800,
    color: '#aaa',
    fontSize: '1.4em'
  },
  crewDetailsInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    width: '100%'
  },
  weekContainer: {
    width: '100%',
    margin: 5,
    padding: 10,
    minHeight: '300px',
    border: '3px solid #222',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    textAlign: 'center',
    alignSelf: 'stretch'
  },
  dayBreakdownContainer: {

  },
  dayContainer: {
    padding: '10px',
    margin: '10px',
    border: '1px solid #bbb',
    borderRadius: 5,
    backgroundColor: '#ddd'
  },
  jobContainers: {
    // padding: '10px',
    // margin: '10px',
    border: '1px solid #bbb',
    borderRadius: 5,
    backgroundColor: '#fff',
   
  },
  jobContainer: {
    // padding: '10px',
    margin: '5px',
    border: '1px solid #bbb',
    borderRadius: 5,
    backgroundColor: '#eee',
    cursor: 'pointer'
  },
  paddedStuff: {
    padding: 15
  },
  tableRow: {
    height: 25,
    padding: 5,
    margin: 5
  },
  daysArea: {
  }
}))

function HMIMtcSchedule(props) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    pending: false,
    sites: [],
    crews: [],
    crew: null,
    groupedData: {}
  })
  const [highlightedOption, setHighlightedOption] = useState(null);
  // const [chosenWeek, setChosenWeek] = React.useState('All')
  // const dfl = `${props.url}/pickupTruck.svg`
  // const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const chosenWeek = searchParams.get('chosenWeek');
  const day = searchParams.get('day');

  React.useEffect(() => {
    let isMounted = true
    const fetchData = async () => {
      const resp = await axios.get(`${props.url}/api/hmi/getmtcscheduledata`)
      console.log('HMI Schedule Data', resp.data)
      if (resp && resp.data && resp.data.hmiCrews && isMounted) {
       let hmiCrews = resp.data.hmiCrews
        hmiCrews.unshift({
          name: 'None',
          _id: '123456789',
          foremanName: 'Unassigned'
        })
        setState({...state, sites: resp.data.sites, crews: hmiCrews, groupedData: resp.data.data })
      }
    }
    
    fetchData()
    return () => {
      isMounted = false
    }
  }, [])

  const updateCrew = (e, newCrew) => {
    setHighlightedOption(null)
    setState({...state, crew: newCrew })
  }

  const handleHighlightChange = (event, option) => {
    // // console.log('set highlighted option:', option)
    setHighlightedOption(option);
  };

  const clearHighlightedOption = () => {
    setHighlightedOption(null)
  }
  const handleTabInteraction = (e) => {
    if (e.key === 'Tab' && e.target.id && highlightedOption) {
      // // console.log('Tab pressed on:', highlightedOption);
      // // console.log('e.target.id', e.target.id)
      if (e.target.id) {
        // // console.log('Set based on highlighted option - clear HIGHLIGHTED OPTION')
        setHighlightedOption(null)
      } else {
        // // console.log('No idea what to set here???', e.target)
      }
    } else if (e.key === 'Tab' ) {
      // // console.log('Tab Interaction:', e)
      // // console.log('e.target.id', e.target.id)
      // // console.log(e.target.value)
    // // console.log(e.target)
      if (e.target.id === 'crew') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        state.crews.filter(o => {
          let str = o['name'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // // console.log(newArr)
        if (newArr && newArr.length) {
          updateCrew(e, newArr[0])
        }
      }
    }
  }

  const handleUpdate = async (site) => {
    // console.log('We have a site to save then we need to update our schedule', site)
    const resp = await axios.post(`${props.url}/api/hmi/updateMtcScheduleBySite`, site)
    // console.log('HMI Schedule Data', resp.data)
    if (resp && resp.data && resp.data.hmiCrews) {
      let hmiCrews = resp.data.hmiCrews
      hmiCrews.unshift({
        name: 'None',
        _id: '123456789',
        foremanName: 'Unassigned'
      })
      setState({...state, sites: resp.data.sites, crews: hmiCrews, groupedData: resp.data.data })
      // setState({...state, sites: resp.data.sites, crews: resp.data.hmiCrews, groupedData: resp.data.data })
    }
  }
  const updateFromDragNDrop = async (data) => {
    console.log('Need to move this guy ', data)
    const { draggableId, destination, source } = data
    const parts = draggableId.split('_')
    const crewId = parts[0]
    const evenOrOdd = parts[1]
    const preferredDay = parts[2]
    const jobId = parts[3]
    console.log('Parts', parts) 
    const sourceParts = source.droppableId.split('_')
    const sourceWeekType = sourceParts[0]
    const sourcePreferredDay = sourceParts[1]
    const destinationParts = destination.droppableId.split('_')
    const destinationWeekType = destinationParts[0]
    const destinationPreferredDay = destinationParts[1]

    let needToMigrateBetweenWeeklyAndQuarterlyMonthly = false
    console.log('Source WEek Type', sourceWeekType)
    console.log('Destination Week Type', destinationWeekType)
    if (sourceWeekType !== destinationWeekType) {
      needToMigrateBetweenWeeklyAndQuarterlyMonthly = true
    }
    if (destinationWeekType === sourceWeekType && destinationPreferredDay === sourcePreferredDay) {
      console.log('240....')
      return // This is about the index...deal w/ it later
    } else {
      console.log('Update this fucker')
      let currentSites = [...state.sites]
      let siteToUpdate = null
      currentSites.map(site => {
        console.log('247 site', site)
        if (site && site._id === jobId) {
          let frequency= site.frequency
          console.log('This is our gu!! - do we needt o update teh tweek type??')
          if (needToMigrateBetweenWeeklyAndQuarterlyMonthly) {
            // Update Frequency ALSO
            console.log('Migrate between boards')
            console.log('Update the frequency based on this shit...')
            if ((destinationWeekType === 'Odd' || destinationWeekType === 'Even') && (sourceWeekType === 'Quarterly' || sourceWeekType === 'Monthly')) {
              console.log('Move to Bi-Weekly')
              frequency = 'Bi-Weekly'
            }
            if ((sourceWeekType === 'Odd' || sourceWeekType === 'Even') && (destinationWeekType === 'Quarterly' || destinationWeekType === 'Monthly')) {
              console.log('Move to Destination Week Type...- monthly/quarterly')
              frequency = destinationWeekType
            }
          } else {
            console.log('Not migrating between boards')
          }
          let newSite = { 
            ...site,
            frequency,
            evenOrOddWeeks: destinationWeekType,
            preferredDay: destinationPreferredDay,
            dayOfTheWeek: destinationPreferredDay
         }
         siteToUpdate = newSite
         // console.log('return this one', newSite)
         return newSite
          } else {
          return site
        }
      })
      console.log('Update this one', siteToUpdate)
      const resp = await axios.post(`${props.url}/api/hmi/updateMtcScheduleBySite`, siteToUpdate)
      // console.log('HMI Schedule Data', resp.data)
      if (resp && resp.data && resp.data.hmiCrews) {
        let hmiCrews = resp.data.hmiCrews
        hmiCrews.unshift({
          name: 'None',
          _id: '123456789',
          foremanName: 'Unassigned'
        })
        setState({...state, sites: resp.data.sites, crews: hmiCrews, groupedData: resp.data.data })
        // setState({...state, sites: resp.data.sites, crews: resp.data.hmiCrews, groupedData: resp.data.data })
      }
    }


  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
          <Grid container>
          <Grid item lg={12} xs={12} md={12} sm={12}>
            <Autocomplete
                  id="crew"
                  options={state.crews }
                  getOptionLabel={(option) => {
                    const name = `${option.name } (${option.foremanName})`
                    return name
                  }}
                  onHighlightChange={handleHighlightChange}
                  // style={{ width: 200 }}
                  className={classes.paddedStuff}
                  // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
                  onChange={updateCrew}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.crew}
                  onBlur={clearHighlightedOption}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="HMI Crew" variant="outlined" onKeyDown={handleTabInteraction} />}
                />
          </Grid>
          <Grid item lg={12} xs={12} md={12} sm={12}>
          <DetailsArea {...props} state={state} setState={setState} updateParent={handleUpdate} handleDropUpdate={updateFromDragNDrop} />
          </Grid>
          
          </Grid>
      </Paper>
    </div>
    
    

  )
}
const days = [
  'None',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'No Preference'
]

const DetailsArea = (props) => {
  // console.log('Details Props::', props)
  const classes = useStyles()
  const [currentCrewIndex, setCurrentCrewIndex] = useState(0)
  const numRef = React.useRef(0)
  React.useEffect(() => {
    numRef.current = currentCrewIndex;
  }, [currentCrewIndex])

  let crews = (props.state.crews) ? props.state.crews : []
  //  console.log('Crews 278:', crews)
  // // console.log(numRef)
  let crew = (crews && crews.length) ? crews[currentCrewIndex] : { name: 'crew name' };
  // console.log('Crew:', crew)
  if (props.state && props.state.crew && props.state.crew._id) {
    
    let index = props.state.crews.findIndex(crew => (crew._id === props.state.crew._id))
    if (index > -1) {
      crew = props.state.crews[index]
      numRef.current = index
    }
  }
  const data = props.state.groupedData
  // console.log('Data to drive:', data)
  let dataToDisplay = null
  let oddWeekData = null
  let evenWeekData = null
  let monthlyData = null
  let quarterlyData = null
  if (data && data[crew._id]) {
    // console.log('Display the right data', data)
    const theRightData = data[crew._id]
    console.log(theRightData)
    dataToDisplay = theRightData
    if (theRightData && theRightData.oddWeeks) oddWeekData = dataToDisplay.oddWeeks
    if (theRightData && theRightData.evenWeeks) evenWeekData = dataToDisplay.evenWeeks
    if (theRightData && theRightData.monthly) monthlyData = dataToDisplay.monthly
    if (theRightData && theRightData.quarterly) quarterlyData = dataToDisplay.quarterly
  }
  
  const handlePrevCrew = () => {
    // // console.log('Previous Crew!!', numRef)
    const nextIndex = (numRef.current - 1) > -1 ? numRef.current - 1 : crews.length - 1
    // // console.log('Next Index', nextIndex)
    // setCurrentCrewIndex(nextIndex);
    props.setState({...props.state, crew: props.state.crews[nextIndex]})
  }

  const handleNextCrew = () => {
    // // console.log('Next Crew!!', numRef)
    const nextIndex = (numRef.current + 1) < crews.length ? numRef.current + 1 : 0;
    props.setState({...props.state, crew: props.state.crews[nextIndex]})
    // setCurrentCrewIndex(nextIndex);
  }
  const handleUpdateFromSchedule = (data) => {
    // console.log('Update...', data)
    props.updateParent(data)
  }
  function handleOnDragEnd(result) {
    // console.log('DRAG END:', result)
    if (!result.destination || result.destination === result.source) return;
    props.handleDropUpdate(result)
  }
  // console.log('Crew.name:::', crew)
  return (
    <div className={classes.detailsContaner} >
       <div className={classes.crewDetailsInnerContainer}>
        <Button
         onClick={handlePrevCrew}
         variant="outlined"
        >PREV CREW</Button>
        <Button
          variant="outlined"
           onClick={handleNextCrew}>NEXT CREW</Button>
      </div>
      <div className={classes.crewDetailHeader} >{ (crew && crew.name) ? crew.name.toUpperCase() : 'Choose a Crew' } {crew.foremanName ? `(${crew.foremanName})` : '' }</div>
      <div className={classes.crewDetailsInnerContainer}>
          <div>{weekType} Week (#{weekOfTheYear})</div>
          
      </div>  
      <DragDropContext onDragEnd={handleOnDragEnd}>
   
   
      <div className={classes.crewDetailsInnerContainer}>
        <div className={classes.weekContainer}>
          <div>Odd Week</div>
          {
            oddWeekData ? <DayBreakdownByWeek crew={crew} week='Odd' data={oddWeekData} state={props.state} updateParent={handleUpdateFromSchedule} /> : null
          }

        </div>
        <div className={classes.weekContainer}>
          <div>Even Week</div>
          {
            evenWeekData ? <DayBreakdownByWeek crew={crew} week='Even' data={evenWeekData} state={props.state} updateParent={handleUpdateFromSchedule}/> : null
          }
          
        </div>
      </div>
      <div className={classes.crewDetailsInnerContainer}>
        <div className={classes.weekContainer}>
            <div>Monthly</div>
            {
              monthlyData ? <DayBreakdownByWeek crew={crew} week='Monthly' data={monthlyData} state={props.state} updateParent={handleUpdateFromSchedule} /> : null
            }
            
        </div>
        <div className={classes.weekContainer}>
            <div>Quarterly</div>
            
            { quarterlyData ? <DayBreakdownByWeek crew={crew} week='Quarterly' data={quarterlyData} state={props.state} updateParent={handleUpdateFromSchedule} /> : null } 
        </div>
      </div>
      </DragDropContext>
    </div>
  )
}

const includeThisSite = (site, day, week, crewId) => {
  let isIncluded = false
  // console.log('Do we include this one??', site)
  // console.log(site.frequency)
  // console.log('Day: %s -- Week %s -', day, week)
  // console.log('Crew: %s --- Site.crew %s ', crewId, site.crew)
  if (site.crew.toString() === crewId.toString()) {
    if (week === 'Monthly' || week === 'Quarterly') {
      // Include Monthly
      if (week === site.frequency && site.preferredDay === day) {
        isIncluded = true
      }
    } else {
      if (site.frequency === 'Daily') isIncluded = true
      if (site.frequency === 'Bi-Weekly' && week === site.frequency && site.preferredDay === day) {
        isIncluded = true
      }
      if (site.frequency === 'Twice a Week' && (site.preferredDays && site.preferredDays.length && site.preferredDays.includes(day))) {
        isIncluded = true
      }
      if (site.frequency === 'Every Other Day (M-W-F)' && (day === 'Monday' || day === 'Wednesday' || day === 'Friday')) {
        isIncluded = true
      }
    }
    // console.log('Crew Match: Include It:', isIncluded)
  }
  // (week !== 'Monthly' && week !== 'Quarterly') && ((site.frequency === 'Bi-Weekly' && site.evenOrOddWeeks === week) || site.frequency === 'Weekly') && site.preferredDay === day && site.crew === crewId)
  // else if (week === 'Monthly' && site.frequency == 'Monthly' && site.preferredDay === day && site.crew === crewId) {
                

  //   // // console.log('Return monthly', site)
  //   return site
  // } else if (week === 'Quarterly' && site.frequency == 'Quarterly' && site.preferredDay === day && site.crew === crewId) {
      

  //   // console.log('Return quarterly', site)
  //   return site
  // } else {
  //   // // console.log('NO MATCH!', site)
    
  // }
  return isIncluded
}

const DayBreakdownByWeek = (props) => {
  const classes = useStyles()
  const data = props.data
  const crewId = props?.crew?._id
  const week = props.week
  console.log('Week Type:', week)
  console.log('Crew ID:', crewId)
  console.log('Dat', data)
  return (
    <div className={classes.dayBreakdownContainer}>
      {
        days.map((day, index) => {
          // console.log('Day', day)
          let filteredSites = []
          if (data && data[day]) filteredSites = data[day]
          // console.log('Site', filteredSites)
          // let filteredSites = [...sites].filter(site => {
          //   // // console.log('Fitler this one', site)
          //   // // console.log('Site.crew::', site.crew)
          //   if (!site.preferredDay || site.preferredDay === 'None') {
          //     // // console.log('NO PREFERRED DAY!!!', site)
          //   }
          //   if (!site.crew || site.crew === 'None') {
          //     // // console.log('No Assigned crwe!!', crewId)
          //     // site.crew = 'None'
          //     // return site
          //   }
          //   console.log('Day: %s --- Week %s ', day, week)
          //   if (includeThisSite(site, day, week, crewId)) {
          //     return site
          //   } 
          // })
          return (
            <div key={index} className={classes.dayContainer}>
              <div>{day}</div>
              <div><JobListByDay jobs={filteredSites} updateParent={props.updateParent} week={week} day={day} crewId={crewId} /></div>
            </div>
          )
        })}
    </div>
  )
}

const JobListByDay = (props) => {
  const classes = useStyles()
  // console.log('Props for joblist by day', props)
  const [showEditJobsiteModal, setShowEditJobsiteModal] = React.useState(false)
  const [activeSite, setActiveSite] = React.useState({})

  const handleDblClickOpenJobsite = (job) => {
    // // console.log('Open this site', job)
    setActiveSite(job)
    setShowEditJobsiteModal(true)
  }
  const handleToggleEditJobSiteModal = (d) => {
    // Toggle The Jobsites
    // // console.log('Toggle:', d)
    setShowEditJobsiteModal(!showEditJobsiteModal)
   
  }
  
  const updateJobSiteAfterEdit = (site) => {
    // // console.log('Update this one', site)
    const jobSite = site.jobSite
    const updatedSite = {
      ...jobSite,
      crew: site?.crew?._id,
      crewSize: site.crewSize,
      crewName: site?.crew?.name,
      amount: site.amount,
      amountType: site.amountType,
      preferredDay: site.preferredDay,
      preferredDays: site.preferredDays,
      frequency: site.frequency,
      evenOrOddWeeks: site.evenOrOddWeeks,
      irrigationCheckFrequency: site.irrigationCheckFrequency,
      mowingRequirements: site.mowingRequirements,
      mowingTime: site.mowingTime,
      estimatedHours: site.estimatedHours
    }
    // // console.log('Save this in our list of sites...', updatedSite)
    props.updateParent(updatedSite)
  }


  return (
    <div className={classes.jobsContainer}>
      <EditJobSiteModal open={showEditJobsiteModal} {...props} activeSite={activeSite} closeAddJobsiteModal={handleToggleEditJobSiteModal} updateParent={updateJobSiteAfterEdit} />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Drag</TableCell>
                    <TableCell>Jobsite Name</TableCell>
                    <TableCell>Address</TableCell>
                    {/* <TableCell>Foreman</TableCell> */}
                    <TableCell>Day</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Frequency</TableCell>
                  </TableRow>
                </TableHead>
                
               
              <Droppable droppableId={`${props.week}_${props.day}`}>
              
                {(provided, snapshot) => (
                  
                  <DefaultLineItemContainer className="listItem" {...provided.droppableProps} ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                {
                props.jobs.map((job, index) => {
                  console.log('631 JOB', job)
                  let dragId = `${props.crewId}_${props.week}_${props.day}_${job._id}_${props.week}_${props.day}_${index}`
                  return (
                    <Draggable key={`${dragId}`} draggableId={dragId} index={index} style={{width: '100%'}}>
                    {(provided, snapshot) => (
                      <DefaultLineItemRow className={classes.tableRow}  onDoubleClick={ e => handleDblClickOpenJobsite(job) } key={index} 
                        ref={provided.innerRef} {...provided.draggableProps}
                          isDragging={snapshot.isDragging}>
                         <TableCell {...provided.dragHandleProps} style={{ flexBasis: "5%" }}>
                          <IconButton disabled>
                            <DragIndicatorIcon />
                          </IconButton>
                        </TableCell>  

                       <TableCell>{job.name}</TableCell>
                        <TableCell>{job.address} {job.buildingNumber ? job.buildingNumber : ''} {job.communityName ? job.communityName : ''}</TableCell>
                        {/* <TableCell>Foreman</TableCell> */}
                        <TableCell>
                        {
                            job.frequency === 'Twice a Week' ? (
                            
                                
                              job.preferredDays.length > 0 ? (
                                
                                      
                                job.preferredDays.map((day, index) => {
                                          return (
                                            <div key={index} className={classes.daysArea}>{day}</div>
                                          )
                                        })
                                      
                                  ) : 'None'
                                
                            ) :  job.frequency === 'Every Other Day (M-W-F)' ? 'Monday-Wednesday-Friday' : job.preferredDay
                          }
                        </TableCell>
                        <TableCell>{job?.estimatedHours || 0}</TableCell>
                        <TableCell>{job.frequency}</TableCell>
                    </DefaultLineItemRow>
                  )}
                  </Draggable>
                  )
                  })
                }
                 {provided.placeholder}
                </DefaultLineItemContainer>
                )}
                </Droppable>
              </Table>
       

    </div>
  )
}

// export default React.memo(MyComponent)
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    crews: state.crews,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(HMIMtcSchedule)
