exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".recovery_recover__10a2m {\n  margin: 20px;\n  text-align: left; }\n\n.recovery_title__3BokE {\n  padding-left: 30px;\n  padding-top: 20px; }\n\n.recovery_content__1jJil {\n  margin: 10px;\n  padding: 20px;\n  padding-top: 0; }\n\n.recovery_logo__35OAM {\n  margin-left: 0;\n  margin-right: 0; }\n", ""]);

// Exports
exports.locals = {
	"recover": "recovery_recover__10a2m",
	"title": "recovery_title__3BokE",
	"content": "recovery_content__1jJil",
	"logo": "recovery_logo__35OAM"
};