import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AssignedEntityComponent from '../Entities/AssignedEntityComponent'
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box'
import WarrantyUploadModal from './WarrantyUploadModal'
import LotInventoryLineItemComponent from '../LotInventory/LotInventoryLineItemComponent'
import EquipmentAutocomplete from './EquipmentRepairDetails';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  rowContainer: {
    // border: '3px solid red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowItem: {
    // border: '2px solid blue',
    fontSize: '1.25em',
    padding: 5,
    margin: 5
    // marginRight: 15
  },
  rowImage: {
    // border: '1px solid green',
    display: 'flex',
    alignSelf: 'stretch'
    // margin: -10,
    // padding: -5,
  },
  formControl: {
    verticalAlign: 'bottom',
    fontSize: '0.8em',
    marginTop: 5
  },
  repairDetailsContainer: {
    marginBottom: 10,
    // padding: 10
  }
}));

const Divisions = [
  'Landscape',
  'Hardscape',
  'Maintenance',
  'Pools',
  'Concrete',
  'Metal Fabrication',
  'Trucking',
  'Crane',
  'Specialty',
  'Tech Service',
  'Mechanic',
  'New River Nursery',
  'Carefree Nursery',
  'Concrete Yard',
  'ADOT',
  'Maricopa County',
  'Commercial',
  'Office',
  'General Repair'
]

const theDate = moment(new Date()).add(1, 'week').format('YYYY-MM-DD')
const defaultValues = {
  status: 'New',
  projectName: '',
  poNumber: null,
  amount: null,
  poType: 'Choose',
  client: null,
  entity: null,
  date: theDate,
  urgent: false,
  division: '',
  warranty: false,
  showWarrantyUploadModal: false,
  purchasedItems: [],
  equipmentInRepair: {},
  isSubcontractor: false
}

const CreatePOModal = (props) => {
  // console.log('CreatePOModal Props: ', props)
  const classes = useStyles();
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [clients, setClients] = React.useState([])
  const [vendor, setVendor] = React.useState({})
  const [project, setProject] = React.useState({})
  const [projects, setProjects] = React.useState({})
  const [vendors, setVendors] = React.useState([])
  const [state, setState] = React.useState(defaultValues)
  const [requestor, setRequestor] = React.useState({})
  const [clientWorkorders, setClientWorkorders] = React.useState([])
  const [workorder, setWorkorder] = React.useState({})
  const [activeEntity, setEntity] = React.useState({})
  const [filteredClients, setFilteredClients] = React.useState([])
  const [filteredProjects, setFilteredProjects] = React.useState([])
  const [po, setPO] = React.useState({})
  const [showWarrantyUploadModal, setShowWarrantyUploadModal] = React.useState(false)

  const [equipmentList, setEquipmentList] = useState([]);

  useEffect(() => {
    let isMounted = true

    fetchEquipment()
    return () => {
      isMounted = false
    }
  }, [])

  const fetchEquipment = async () => {
    // console.log('Fetch Equipment', activeEntity)
    let theEntity = activeEntity?._id ? activeEntity?._id : 'All'// if (!activeEntity) 
    console.log('Entity', theEntity)
    const response = await axios.get(`${props.url}/api/equipment/listEquipment?entity=${theEntity}`)
    // console.log('Got the equipment', response.data)
    // setEquipmentList(response.data)
    if (response.data && response.data?.equipment?.length) {
      // console.log('Set hte equipment', response.data.equipment)
      setEquipmentList(response.data.equipment)
    }
  }

  const handleAddEquipment = (newEquipment) => {
    console.log('add new equipment', newEquipment)
    setState({...state, equipmentInRepair: newEquipment })
    inputRef.current.equipment = newEquipment?._id
    setEquipmentList([...equipmentList, newEquipment]);
  };

  const inputRef = React.useRef({
    invoiceNumber: '',
    amount: 0,
    date: theDate,
    urgent: false,
    division: '',
    purchasedItems: []
   })

   React.useEffect(() => {
    //// console.log('Create PO Modal Use Effect - set Enttity', props.user.entities)
    if (props.user && props.user.entities && props.user.entities.length) {
    // console.log('Set the entity...')
      setEntity(props.user.entities[0])
      
    }
   }, [props])

  React.useEffect(() => {
    // console.log('create po modal use effect')
    let isMounted = true
    const fetchClients = async () => {
      // console.log('Fetch clients')
      setLoading(true)
      // ?byEntity=610a290f45ef7427f94b2e78
      let url = `${props.url}/api/customer/clients`
      axios.get(url).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          // console.log('Set Clients: ', response.data)
          if (isMounted) {
            // setFilteredClients(response.data)
            setClients(response.data)
          }
        }
      })
    }
    const fetchVendors = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/getVendors`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setVendors(response.data)
        }
      })
    }

    const fetchProjects = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/projects/listProjects`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data && response.data.projects && response.data.projects.length) {
          if (isMounted) {
            setProjects(response.data.projects)
            setFilteredProjects(response.data.projects)
          }
        }
      })
    }
    // if (props.clients && props.clients.length) {
    //   setClients(props.clients)
    // }
    if (props.open) {
      fetchClients()
      fetchVendors()
      fetchProjects()
      // Set Requestor to user
      // console.log('set requestor', props)
    // console.log('USeriD;', props.user._id)
      let index = props.users.findIndex(item => (item._id === props.user._id))
    // console.log('User Index INDEX: ', index)
      if (index > -1) {
      // console.log('Set this too:', props.users[index])
        setRequestor(props.users[index])
        inputRef.current.requestor = props.users[index]
      } else {
      // console.log(props.user._id)
      // console.log('emps', props.users)
      }
    }

    return () => {
      isMounted = false
    }
  }, [props.open])

  const handleSave = () => {
    // console.log(inputRef)
    setLoading(true)
    // setClient({})
    // console.log('Create a po reqeust!!', props)
    // setState({...state, status: 'New', projectName: ''})
    axios({
      method: 'post',
      url:  `${props.url}/api/purchaseorders/createPurchaseRequest`,
      data: inputRef.current
    }).then(response => {
      setLoading(false)
    console.log('Response', response.data)
      if (response.data && response.data._id) {
        let canApprove = false
        props.closeModal()
        setVendor(null)
        setState(defaultValues)
        setClient(null)
        setProject(null)
        setProjects([])
        inputRef.current = {}
        if (props.user && props.user.entities && props.user.entities.length > 1) setEntity(null)
        if (props.user && props.user.permissions && props.user.permissions.length) {
          props.user.permissions.map(perm => {
            if (perm.type === 'Approve POs' && perm.view === true) {
            console.log("Can APPROVE")
              canApprove = true
            }
          })
        }
        if (canApprove) {
          console.log('go to approval', props)
          // setPO(response.data)
          props.closeModal()
          // setState({...state, poNumber: response.data.number, counter: state.counter++ })
          history.push(`/poapprovals/${response.data._id}`)
        } else {
          console.log('No APproval....')
          props.closeModal()
  
          if (props.user && props.user.entities && props.user.entities.length > 1) setEntity(null)
         
          if (state.warranty) {
            // props.closeModal()
            setPO(response.data)
            
            setShowWarrantyUploadModal(true)
            setState({...state, showWarrantyUploadModal: true })
            inputRef.current = {}
            
            props.notification({
              type: 'success',
              title: `Warranty Details Needed`,
              message: 'Please Upload a Picture of the Items Requiring Warranty Replacement Now'
            })
          } else {
            props.closeModal()
            setState({...state, ...defaultValues})
            inputRef.current = {}
            props.notification({
              type: 'success',
              title: `Purchase Request Generated`,
              message: 'Your PO Number is Pending Approval Now - You Will Be Notified When Complete'
            })
          }
        }

        // if (response.data && response.data.po && response.data.po._id) inputRef.current._id = response.data.po._id
        // inputRef.current.poNumber = response.data.poNumber
        // setState({...state, poNumber: response.data.poNumber})
        // props.updateParent(response.data)
        // handleClose()
        // props.closeModal()
      } else {
        props.notification({
          type: 'warning',
          title: 'Purchase Request Failed',
          message: 'Something Went Wrong - Please Try Again'
        })
      }

    })
  }

  const updateCustomer = (e, customer) => {
  // console.log('Update the customer:', customer)
    if (customer && customer._id) {
      setClient(customer)
      inputRef.current.client = customer
      // Set our projects
      let ourProjects = projects || []
      // console.log('Today we have %d projects', ourProjects.length)
      let newProjects = ourProjects.filter(item => { 
        // console.log('Item:', item)
        // console.log('%s -- %s ', item.client, customer._id)
        if (item.client._id === customer._id) return item
      })
      // console.log('We will have %d new projects', newProjects.length)
      if (newProjects && newProjects.length) {
        setFilteredProjects(newProjects)
      } else {
        setFilteredProjects([])
      }
      setProject({})
      if (customer && customer.entity) {
        let theEntity = null
        // console.log('Find ENitty', props.entities)
        let index = props.entities.findIndex(item => (item._id === customer.entity))
        // console.log('Entity INdex', index)
        if (index > - 1) {
          theEntity = props.entities[index]
          if (theEntity && theEntity._id) {
            // console.log('Set our entity: ', theEntity)
            inputRef.current.entity = theEntity
            // newState.entity = theEntity._id
            setEntity(theEntity)
            setState({...state, entity: theEntity._id})
          }
          // setEntity(activeEntity)
        }
        // setState({...state, entity: activeEntity._id })
      }
    } else {
    // console.log('Clear Customer, entity, and project')
      inputRef.current.client = null
      setClient(null)
      inputRef.current.entity = null
      // setEntity(null)
      if (props.user && props.user.entities && props.user.entities.length > 1) setEntity(null)
      setProject(null)
      inputRef.current.project = null
      setState({...state, entity: null })
    }

  }

  const updateRequestor = (e, customer) => {
    if (customer && customer._id) {
      setRequestor(customer)
      inputRef.current.requestor = customer
    }
  }
  

  const updateVendor = (e, newVendor) => {
    if (newVendor && newVendor._id) {
      setVendor(newVendor)
      inputRef.current.vendor = newVendor
    }
  }
  const updateProjects = (e, newProject) => {
    // console.log('Set our other shit based ont his...', newProject)
    let newState = {}
    if (newProject && newProject._id) {
      setProject(newProject)
      inputRef.current.project = newProject._id
      inputRef.current.projectName = newProject.name
      newState.project = newProject
      // Set Entity
      if (newProject && newProject.entity) {
        let theEntity = null
        // console.log('Find ENitty', props.entities)
        let index = props.entities.findIndex(item => (item._id === newProject.entity))
        // console.log('Entity INdex', index)
        if (index > - 1) {
          theEntity = props.entities[index]
          if (theEntity && theEntity._id) {
            // console.log('Set our entity: ', theEntity)
            inputRef.current.entity = theEntity
            newState.entity = theEntity._id
            setEntity(theEntity)
          }
          // setEntity(activeEntity)
        }
        // setState({...state, entity: activeEntity._id })
      }
      // Set Client
      let clientId = null
      if (newProject && newProject.client && newProject.client._id) {
        clientId = newProject.client._id
      } else if (newProject.client) {
        clientId = newProject.client
      }
      if (clientId) {
        let index = clients.findIndex(item => (item._id === clientId))
        // console.log('Client INdex', index)
        if (index > - 1) {
          // theEntity = props.entities[index]
          inputRef.current.client = clients[index]
          newState.client = clients[index]
          // setEntity(activeEntity)
          setClient(clients[index])
        }
      }
      // PO Type
      inputRef.current.poType = 'Client Project/Job'
      newState.poType = 'Client Project/Job'
      // console.log('Set NEw State', newState)
      // console.log('Input Ref:', inputRef)
      setState({...state, ...newState})
    }
  }

  const handleUpdateVisibleEntity = (theNewEntity) => {
  // console.log('Entity to assigN: ', activeEntity)
    if (theNewEntity && theNewEntity._id) {
      inputRef.current.entity = theNewEntity._id
      setEntity(theNewEntity)
      // Update our Client list
      let currentClients = clients
      // console.log('# of current clients', currentClients.length)
      let newClients = currentClients.filter(item => { 
        // console.log('item: ', item)
        // console.log(item.entity)
        // console.log(activeEntity._id)
        if (item.entity === theNewEntity._id) return item
      })
      // .filter(item => return (item.entity === activeEntity._id))
      // console.log('New Clients', newClients)
      setFilteredClients(newClients)
      setProject({})
      setClient({})
      fetchEquipment(theNewEntity)
      setState({...state, entity: theNewEntity._id })
    } else {
      inputRef.current.entity = null
      // setEntity(null)
      if (props.user && props.user.entities && props.user.entities.length > 1) setEntity(null)
      // Update our Client list
      // .filter(item => return (item.entity === activeEntity._id))
      // console.log('New Clients', newClients)
      setFilteredClients([])
      fetchEquipment()
      setProject({})
      setClient({})
      setState({...state, entity: null })
    }

  }

  const updateWorkorder = (e, wo) => {
    // console.log('update workorder:', wo)
    if (wo && wo._id) {
      setWorkorder(wo)
      // fetchServiceVisits(wo._id)
      inputRef.current.workorder = wo
      setState({...state, workorder: wo })
    }
  }

  const handleTabInteraction = (e) => {
    let string = e.target.value
    let newArr = []
  // console.log('String Tabbed on', string)
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'customername') {
        clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updateCustomer(e, newArr[0])
        }
      }
      if (e.target.id === 'project') {
        projects.filter(o => {
          let projectName = o['name'] || ''
          let str = projectName.toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updateProjects(e, newArr[0])
        }
      }
      if (e.target.id === 'vendorname') {
        vendors.filter(o => {
          let str = o['name'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setVendor(newArr[0])
          updateVendor(null, newArr[0])
        }
      }
      if (e.target.id === 'entity') {
        props.entities.filter(o => {
          let str = o['name'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setVendor(newArr[0])
          handleUpdateVisibleEntity(newArr[0])
        }
      }
      if (e.target.id === 'workorder') {
        clientWorkorders.filter(option => {
          let retValue = ''
          if (option && option.number) retValue = `WO #${option.number} -- ${option.serviceName ? option.serviceName : ''} - ${option.jobName ? option.jobName : '' } - ${option.jobNumber ? option.jobNumber : ''} Scheduled: ${moment(option.scheduledDate).format('MM/DD/YYYY')}`
          // return retValue
          // console.log('Search', retValue)
          // let str = `${option.serviceName ? option.serviceName : ''} - ${option.jobName ? option.jobName : '' } - ${option.jobNumber ? option.jobNumber : ''}`.toString().toLowerCase()
          // o['displayName'].toString().toLowerCase()
          if (retValue.includes(string.toLowerCase())) {
            return newArr.push(option)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
        // console.log(newArr[0])
          // setWorkorder(newArr[0])
          updateWorkorder(e, newArr[0])
        }
      }
      if (e.target.id === 'requestername') {
        props.employees.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updateRequestor(null, newArr[0])
        }
      }
      
    }
  }

  const handleClose = () => {
    inputRef.current = {}
    setState(defaultValues)
    setWorkorder({})
    setClient({})
    setVendor({})
    setProject({})
    props.closeModal()
  }
  const updateStatus = (e, status) => {
    inputRef.current.status = status
  }

  const updateRef = (e) => {
    // console.log('UPdate REf %s - %s ', e.target.id, e.target.value)
    inputRef.current[e.target.id] = e.target.value
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleInvoiceTypeChange = (e) => {
    // setInvoiceType(e.target.value)
    inputRef.current.poType = e.target.value
    setState({...state, poType: e.target.value })
  }

  const handleDivisionChange = (e) => {
    inputRef.current.division = e.target.value
    setState({...state, division: e.target.value })
  }

  const updatePOinDB = (e) => {
    // inputRef.current[e.target.id] = e.target.value
    // setState({...state, [e.target.id]: e.target.value })
    setLoading(true)
    // setClient({})
    // console.log('Update PO: ', inputRef.current)
    // setState({...state, status: 'New', projectName: ''})
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/autoUpdatePO`,
      data: inputRef.current
    }).then(response => {
      // setTimeout(() => {
      setLoading(false)
      handleClose()
      // }, 200)

      // if (response.data && response.data.poNumber) {
      //   props.notification({
      //     type: 'success',
      //     title: `PO #${response.data.poNumber} Generated`,
      //     message: 'You Created a Purchase Order - Well Done!'
      //   })
      //   setState({...state, poNumber: response.data.poNumber})
      //   // props.updateParent(response.data)
     
      //   // props.closeModal()
      // }

    })
  }

  const updateUrgency = () => {
    inputRef.current.urgent = !state.urgent
    setState({...state, urgent: !state.urgent })
  }
  const updateWarranty = () => {
    inputRef.current.warranty = !state.warranty
    setState({...state, warranty: !state.warranty })
  }

  const updateIsSubcontractor = () => {
    inputRef.current.isSubcontractor = !state.isSubcontractor
    setState({...state, isSubcontractor: !state.isSubcontractor })
  }

  const handleWarrantyUploadResponse = (data) => {
  // console.log('Warranty update!', data)
    setShowWarrantyUploadModal(false)
    // setState({...state, showWarrantyUploadModal: false })
  }

  const handleAddItemsPurchased = (listOfItemsPurchased) => {
    console.log('Purchaesd Items on this PO', listOfItemsPurchased)
    inputRef.current.purchasedItems = listOfItemsPurchased
    setState({...state, purchasedItems: listOfItemsPurchased })
  }

  return (
    <div >
      <WarrantyUploadModal po={po} open={showWarrantyUploadModal} closeModal={handleWarrantyUploadResponse} />
      <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create New Purchase Request</DialogTitle>
          <DialogContent >
            <DialogContentText>
              Please Provide Purchase Request Information Below
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControl fullWidth >
                  {/* <InputLabel style={{marginLeft: 10}} >Type of Purchase Order</InputLabel> */}
                  <TextField
                    id="poType"
                    value={state.poType}
                    fullWidth
                    label="PO Type"
                    select
                    variant="outlined"
                    onChange={handleInvoiceTypeChange}
                    // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                  >
                    <MenuItem value={'Client Project/Job'}>Client Project/Job</MenuItem>
                    <MenuItem value={'Tools'}>Tools</MenuItem>
                    <MenuItem value={'Inventory'}>Inventory</MenuItem>
                    <MenuItem value={'Marketing'}>Marketing</MenuItem>
                    <MenuItem value={'Repair'}>Repair</MenuItem>
                    <MenuItem value={'Office'}>Office Supplies</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </TextField>
                  <FormHelperText>What Kind of PO Is This?</FormHelperText>
                </FormControl>
                {
                  state.poType === 'Client Project/Job' ? (
                    <React.Fragment>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Autocomplete
                        id={`customername`}
                        options={props.clients || []}
                        getOptionLabel={(option) => {
                          // console.log('Options: ', option)
                          return option.displayName || ''
                        }}
                        // style={{ width: 200 }}
                        // style={{marginBottom: '20px'}}
                        onChange={updateCustomer}
                        disabled={loading}
                        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                        value = {client}
                        // ref={inputRef.client}
                        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                        renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} variant="outlined" />}
                      />

                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Autocomplete
                            id={`project`}
                            options={filteredProjects || []}
                            getOptionLabel={(option) => {
                              // console.log('Options: ', option)
                              return option.name || ''
                            }}
                            // style={{ width: 200 }}
                            // style={{marginBottom: '20px'}}
                            onChange={updateProjects}
                            disabled={loading}
                            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                            value = {project}
                            // ref={inputRef.client}
                            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                            renderInput={(params) => <TextField {...params} label="Project" helperText="Is This Associated With a Project?" onKeyDown={handleTabInteraction} variant="outlined" />}
                          />
                      </Grid>
                    </React.Fragment>
                    
                  ) : null
                }
                {
                  state.poType === 'Repair' ? (
                    <div className={classes.repairDetailsContainer}>
                      <EquipmentAutocomplete equipmentList={equipmentList} onAddEquipment={handleAddEquipment} />
                    </div>
                  ) : null
                }
                {
                (props.user && props.user.entities && props.user.entities.length > 1) ? (
                  <div style={{ marginTop: 10}}>
                    <AssignedEntityComponent entity={activeEntity} removeOutline={false} title={'Assigned Entity'} disabled={loading} user={props.user} updateParent={handleUpdateVisibleEntity} />
                  </div>
                    
                
              
                ) : (
                  (activeEntity && activeEntity._id) ? (
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <img alt="Logo" src={`${process.env.PUBLIC_URL}/${activeEntity._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 220, maxHeight: 100}} /> 
                    </div>
                    
                  ) : ''
                )
              }
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth >
                    <TextField
                      id="division"
                      select
                      fullWidth
                      variant="outlined"
                      label="Division"
                      onChange={handleDivisionChange}
                      value={state.division}
                    >
                      {
                        Divisions.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                          )
                        })
                      }
                    </TextField>
                    <FormHelperText>What Division?</FormHelperText>
                  </FormControl>
                  
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id={`vendorname`}
                    options={vendors || []}
                    getOptionLabel={(option) => {
                      // console.log('Options: ', option)
                      return option.name || ''
                    }}
                    // style={{ width: 200 }}
                    // style={{marginBottom: '20px'}}
                    onChange={updateVendor}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {vendor}
                    // ref={inputRef.client}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Preferred Vendor" helperText="Do You Have a Preferred Vendor?" onKeyDown={handleTabInteraction} variant="outlined" />}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  id="date"
                  label="When Is This Needed?"
                  // style={{ margin: 8 }}
                  // ref={inputRef.projectDescription}
                  // placeholder="How Long Do We Have?"
                  helperText="When Do You Need It By?"
                  fullWidth
                  type="date"
                  rows={3}
                  value={state.date}
                  onChange={updateRef}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                  <Box display="flex" alignItems="center">
                    
                    <FormControlLabel
                      control={<Checkbox checked={state.urgent} onChange={updateUrgency} name="isUrgent" />}
                      label=""
                    />
                    <FormLabel component="legend">Is This an Urgent Request?</FormLabel>
                  </Box>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                    <Box display="flex" alignItems="center">
                      
                      <FormControlLabel
                        control={<Checkbox checked={state.warranty} onChange={updateWarranty} name="isWarranty" />}
                        label=""
                      />
                      <FormLabel component="legend">Is This a Warranty Issue?</FormLabel>
                    </Box>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                    <Box display="flex" alignItems="center">
                      
                      <FormControlLabel
                        control={<Checkbox checked={state.isSubcontractor} onChange={updateIsSubcontractor} name="isSubcontractor" />}
                        label=""
                      />
                      <FormLabel component="legend">Is This a Sub-Contractor?</FormLabel>
                    </Box>
                </FormControl>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                {
                  state.warranty ? (
                    <WarrantyDetails />
                  ) : null
                }
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {
                  (state.poType && state.poType === 'Inventory') && <LotInventoryLineItemComponent label={"Items Purchased"} updateParent={handleAddItemsPurchased} />
                }
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="cost"
                  label="How Much Will This Cost?"
                  // style={{ margin: 8 }}
                  // ref={inputRef.projectDescription}
                  placeholder="Estimated Price"
                  helperText="Approximately How Much Will This Cost?"
                  fullWidth
                  onChange={updateRef}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="description"
                  label="What Is This For?"
                  // style={{ margin: 8 }}
                  // ref={inputRef.projectDescription}
                  placeholder="What Do You Need?"
                  helperText="What Would You Like to Buy?"
                  fullWidth
                  multiline
                  rows={3}
                  onChange={updateRef}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
              
            </Grid>

          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            {
              state.poNumber ? (
                <Button color="primary" disabled={loading || !inputRef.current.amount || !inputRef.current.invoiceNumber } onClick={updatePOinDB}>
                {
                  loading ? <CircularProgress /> : 'Save'
                }
              </Button>
              ) : (
                <Button color="primary" disabled={loading || (!activeEntity || (activeEntity && !activeEntity._id)) || (!vendor || (vendor && !vendor._id)) || !state.division || !state.description || !state.date || !state.poType || (state.poType === 'Client Project/Job' && !client._id)} onClick={handleSave}>
                {
                  loading ? <CircularProgress /> : 'Generate Purchase Request'
                }
              </Button>
              )
            }

          </DialogActions>
        </Dialog>
    </div>
  )
}

const WarrantyDetails = (props) => {
  return (
    <div style={{ marginTop: '16px' }}>
      You will need to provide a picture of the damaged items before this will be approved
    </div>
  )
}

  const mapStateToProps = (state) => {
  // console.log('Create Purchase request', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      salesReps: state.salesReps,
      employees: state.employees,
      entities: state.entities,
      clients: state.clients,
      users: state.users,
      equipment: state.equipment
    }
  }
  
  export default connect(mapStateToProps)(CreatePOModal)