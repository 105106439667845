exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".activationHash_activationHash__2PdN2 {\n  margin: 50px;\n  text-align: center; }\n\n.activationHash_title__7a0fX {\n  padding-top: 20px; }\n\n.activationHash_content__wLKQT {\n  margin: 10px;\n  padding: 20px; }\n\n.activationHash_logo__1ClSN {\n  margin-left: 0;\n  margin-right: 0; }\n\n.activationHash_loader__1U2gQ {\n  margin-top: 20px; }\n", ""]);

// Exports
exports.locals = {
	"activationHash": "activationHash_activationHash__2PdN2",
	"title": "activationHash_title__7a0fX",
	"content": "activationHash_content__wLKQT",
	"logo": "activationHash_logo__1ClSN",
	"loader": "activationHash_loader__1U2gQ"
};