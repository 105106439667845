exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".navigation_link__L6qzg {\n  margin-left: 20px;\n  margin-right: 20px;\n  text-decoration: none;\n  cursor: pointer; }\n\n.navigation_white__2pgr4 {\n  color: white; }\n\n.navigation_black__2hfSx {\n  color: black; }\n\n.navigation_flexGrow__ly8On {\n  flex-grow: 1; }\n\n.navigation_theme__3SW-X {\n  top: -10px; }\n", ""]);

// Exports
exports.locals = {
	"link": "navigation_link__L6qzg",
	"white": "navigation_white__2pgr4",
	"black": "navigation_black__2hfSx",
	"flexGrow": "navigation_flexGrow__ly8On",
	"theme": "navigation_theme__3SW-X"
};