import React from 'react';
import styled from 'styled-components';
import { Button, CircularProgress, IconButton, TextField, TextareaAutosize } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Select from "react-select";
import AssessmentIcon from '@material-ui/icons/Assessment';
import Tooltip from '@material-ui/core/Tooltip';
import CalculationModal from './CalculationModal'
import axios from 'axios';
import { connect } from 'react-redux'
import { currencyFormat } from '../../services/common'
import AddNewOptionModal from '../Inventory/options/AddNewOptionModal'
import Autocomplete from '@material-ui/lab/Autocomplete';
import LineItemTypeSelector from './LineItemTypeSelector'

const ConfigurationContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
text-align: center;
border-radius: 15px;
margin-top: 25px;
width: 100%;
height: 100%;
`;

const LabelText = styled.div`
  color: #222;
  font-size: 16px;
  text-align: left;
`;

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    height: '56px', // Replace with the height of your Textfield components
    minHeight: '56px', // Replace with the height of your Textfield components
    boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : null,
    borderColor: state.isFocused ? '#80bdff' : provided.borderColor,
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 9999,
  }),
  menu: base => ({
    ...base,
    opacity: 1
  }),
};
  
const handleFocus = (event) => event.target.select()

const defaultValues = {
  qty: 0,
  unit: null,
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  counter: 0,
  editing: false,
  inventoryItem: null,
  item: null,
  internalNotes: '',
  materialBudget: 0,
  laborHours: 0,
  techHours: 0,
  sprayTechHours: 0,
  operatorHours: 0,
  supervisorHours: 0,
  bobcatDays: 0,
  craneDays: 0,
  smallCraneDays: 0,
  bigCraneDays: 0,
  option: null,
  numberOfDays: 0,
  numberOfMen: 0,
  suggestedLaborTotal: 0,
  lineItemType: 'materials'
}

const unitsOfMeasureAll = [
  {
    value: 'Each',
    label: 'Each'
  },
  {
    value: 'Flat',
    label: 'Flat'
  },
  {
    value: 'Pint',
    label: 'Pint'
  },
  {
    value: 'Quart',
    label: 'Quart'
  },
  {
    value: '1 Gallon',
    label: '1 Gallon'
  },
  {
    value: '2 Gallon',
    label: '2 Gallon'
  },
  {
    value: '3 Gallon',
    label: '3 Gallon'
  },
  {
    value: '5 Gallon',
    label: `5 Gallon`
  },
  {
    value: '10 Gallon',
    label: `10 Gallon`
  },
  {
    value: '15 Gallon',
    label: `15 Gallon`
  },
  {
    value: '25 Gallon',
    label: `25 Gallon`
  },
  {
    value: 'Bare Root',
    label: `Bare Root`
  },
  {
    value: `24" Box`,
    label: `24" Box`
  },
  {
    value: `30" Box`,
    label: `30" Box`
  },
  {
    value: `32" Box`,
    label: `32" Box`
  },
  {
    value: `36" Box`,
    label: `36" Box`
  },
  {
    value: `42" Box`,
    label: `42" Box`
  },
  {
    value: `48" Box`,
    label: `48" Box`
  },
  {
    value: `54" Box`,
    label: `54" Box`
  },
  {
    value: `60" Box`,
    label: `60" Box`
  },
  {
    value: `66" Box`,
    label: `66" Box`
  },
  {
    value: `70" Box`,
    label: `70" Box`
  },
  {
    value: `72" Box`,
    label: `72" Box`
  },
  {
    value: `78" Box`,
    label: `78" Box`
  },
  {
    value: `84" Box`,
    label: `84" Box`
  },
  {
    value: 'Square Foot',
    label: 'Square Foot'
  },
  {
    value: 'Linear Foot',
    label: 'Linear Foot'
  },
  {
    value: 'Flat',
    label: 'Flat'
  },
  {
    value: 'Ton',
    label: 'Ton'
  },
  {
    value: 'create',
    label: 'Add New Size'
  }
]

const ConfigureItem = (props) => {
  console.log('CONFIGURE ITEM42: ', props)
  const [state, setState] = React.useState(defaultValues)
  const [unit, setUnit] = React.useState(null)
  const [showCalculationModal, setShowCalculationModal] = React.useState(false)
  const [sizes, setSizes] = React.useState([])
  const [showNewOptionModal, setShowNewOptionModal] = React.useState(false)

  React.useEffect(() => {
    // console.log('SEt our Line ITem Configurations::', props)
    if (props.inventoryItem && props.inventoryItem._id) {
    // console.log('set Our ITem:', props.inventoryItem)
      if (props.inventoryItem.editing) {
      // console.log('Editing')
        let pricePer = props.inventoryItem.pricePer || 0
        let totalPrice = props.inventoryItem.totalPrice || 0
        let costPer = props.inventoryItem.costPer || 0
        let markup = 1.4
        if (props.estimate && props.estimate.entity && props.estimate.entity === '640a56404965b71a9edd0e7d') {
          markup = 1.15
        }
        if (pricePer === 0 && costPer > 0) pricePer = Number(costPer * markup).toFixed(2)
        let qty = 0
        if (props.inventoryItem.qty) {
          qty = props.inventoryItem.qty
          if (totalPrice === 0) totalPrice = Number(pricePer & qty).toFixed(2)
        }
        let sku = null
        let unit = null
        if (props.inventoryItem.unit) {
          unit = {
            label: props.inventoryItem.unit,
            value: props.inventoryItem.unit
          }
        }
        if (props.inventoryItem.sku) sku = props.inventoryItem.sku
        let id = new Date()
      // console.log('ID: ', id)
        if (props.inventoryItem._id && props.inventoryItem._id !== props.inventoryItem.inventoryItem) {
        // console.log("Props.inventoryIte", props.inventoryItem.inventoryItem)
          id = props.inventoryItem._id
        }
      // console.log('SEt ID:', id)
        let materialBudget = 0
        let internalNotes = ''
        if (props.inventoryItem.materialBudget) materialBudget = props.inventoryItem.materialBudget
        if (props.inventoryItem.internalNotes) internalNotes = props.inventoryItem.internalNotes
        let newState = { _id: id, internalNotes, materialBudget, qty: qty, unit: unit, inventoryItem: props.inventoryItem.inventoryItem, sku: sku, description: props.inventoryItem.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice }
      // console.log('NEW State:', newState)
      
        if (props.inventoryItem.numberOfDays && props.inventoryItem.numberOfMen) {
          let suggestedLaborTotal = Number(props.inventoryItem.numberOfDays) * Number(props.inventoryItem.numberOfMen) * 560
          console.log('Suggested Labor', suggestedLaborTotal)
          newState.suggestedLaborTotal = parseFloat(suggestedLaborTotal).toFixed(2)
          newState.numberOfDays = props.inventoryItem.numberOfDays
          newState.numberOfMen = props.inventoryItem.numberOfMen
          newState.totalManHours = newState.numberOfMen * newState.numberOfDays * 8
        }
        if (props?.inventoryItem?.lineItemType) newState.lineItemType = props.inventoryItem.lineItemType
        setState({...state, ...newState })
   
      } else {
      // console.log('NOte Editing')
        let pricePer = 0
        let totalPrice = 0
        let costPer = props.inventoryItem.price || 0
        let markup = 1.4
        if (props.estimate && props.estimate.entity && props.estimate.entity === '640a56404965b71a9edd0e7d') {
          markup = 1.15
        }
        pricePer = Number(costPer * markup).toFixed(2)
        totalPrice = Number(pricePer).toFixed(2)
        let sku = null
        if (props.inventoryItem.sku) sku = props.inventoryItem.sku
        let newState = { inventoryItem: props.inventoryItem._id, sku: sku, description: props.inventoryItem.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice }
      // console.log('New State', newState)
        setState({...state, ...newState })

      }
    }
  }, [props.inventoryItem])

  React.useEffect(() => {
    let isMounted = true
    const fetchSizes = async () => {
    // console.log('Get our sizes and shit')
      axios
      .get(
        `${props.url}/api/settings/getStuffForNewItem`
        )
      .then(({ data }) => {
        if (isMounted) {
          if (data && data.sizes) {
            setSizes(data.sizes)
          }
        }


      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
    }

    fetchSizes()

    return () => {
      isMounted = false
    }
  }, [])


  const handleSaveNewLineItem = () => {
  // console.log('Save Line Item', state)
  // console.log('Unit', unit)
    let theUnit = ''
    if (state && state.unit && state.unit.value) theUnit = state.unit.value
    // Add this to our Estimate
    let itemToSend = {
      ...state
    }
    itemToSend.item = props.item
    itemToSend.unit = theUnit
  // console.log('Item to send: ', itemToSend)
    props.addLineItemToEstimate(itemToSend)
    setState({...state, ...defaultValues })
  }

  const handleUpdateExistingLineItem = () => {
  // console.log('Update: ', state)
    let itemToSend = {
      ...state
    }
    if (props.item._id && !props.item._id.includes('uuid')) {
    // console.log('Add our inventoryI tem id', props.item._id)
      // itemToSend.inventoryItemId = props.item._id
      itemToSend.invItem = props.item._id
    }
    // itemToSend._id = props.item._id
    // itemToSend.inventoryItemId = state.inventoryItemId
    // console.log('Send this: ', itemToSend)
    // console.log('State: ', state)
    props.updateLineItemOnEstimate(itemToSend)
    // setState({...state, ...defaultValues })
  }

  const handleSelectFocus = (e) => {
    e.preventDefault()
    e.target.focus()
    e.target.setSelectionRange(0, e.target.value.length)
  }



  const handleCostChangeUpdate = (e) => {
    // let pricePer = state.pricePer
    // let totalPrice = 0
    let costPer = e.target.value
    // pricePer = Number(costPer * 1.4).toFixed(2)
    // totalPrice = Number(pricePer * state.qty).toFixed(2)

    // console.log('Cost: %d Price: %d ', costPer, pricePer)
    setState({ ...state, costPer: costPer })
  }

  const handleCostPerBlur = () => {
    let pricePer = state.pricePer
    let costPer = state.costPer
    let markup = 1.4
    if (props.estimate && props.estimate.entity && props.estimate.entity === '640a56404965b71a9edd0e7d') {
      markup = 1.15
    }
    // console.log('State', state)
    if (pricePer === 0) {
      // console.log('Modify price per!!')
      pricePer = parseFloat(Number(costPer) * markup).toFixed(2)
    }
    console.log('price: %s    cost: ', pricePer, costPer)
    let totalPrice = 0
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    console.log('Update total price', totalPrice)
    if (pricePer < Number(costPer * 1.15)) {
      props.notification({
        type: 'warning',
        title: 'Are You Sure??',
        message: `Are you sure you want to charge $${pricePer} if it costs us $${costPer}`
      })
    }
    setState({...state, pricePer: pricePer, totalPrice: totalPrice })
  }

  const handlePriceManualChange = (e) => {
    let pricePer = e.target.value
    let totalPrice = 0
    const costPer = state.costPer
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    setState({...state, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handleQtyUpdate = (e) => {
    let pricePer = state.pricePer
    let totalPrice = 0
    totalPrice = Number(pricePer * e.target.value).toFixed(2)
    setState({...state, qty: e.target.value, totalPrice: totalPrice})
  }

  const handleUnitChange = (e, newUnit) => {
    // console.log('Handle unit change', e.target.value)
    // console.log('new unit', newUnit)
    // setState({...state, unit: newUnit})
    // setUnit(newUnit)
    if (newUnit && newUnit.value === 'create') {
      setShowNewOptionModal(true)
    } else {
      setState({...state, unit: newUnit })
    }
  }

  const handleClear = () => {
    // setState({...state, unit: null })
    // setUnit(null)
    setState({...state, unit: null })
  }

  const handleCalculate = () => {
  // console.log('Do our calculations on this one ourselves!!', state)
    // setState({...state, internalNotes: state.internalNotes += '\nPortal Calculation'})
    setShowCalculationModal(true)
  }

  const handleCloseCalculationModal = () => {
    setShowCalculationModal(false)
  }

  const addCalculationsToEstimate = (data) => {
  // console.log('Gotta add these to our Line iTems:', data)
    setShowCalculationModal(false)
    // if (data && data.length) {
      for (const line of data) {
        if (line.unit && line.unit.value) line.unit = line.unit.value
      // // console.log('Add this one:', line)
      }
      //   if (line.unit && line.unit.value) line.unit = line.unit.value
      props.addLineItemToEstimate(data, true)
      // }
    // }
    // setShowCalculationModal(false)
    // setState({...state, ...data })
  }
// console.log('STATE::: ', state)
  // console.log('Unit', state.unit)

  const formatOption = (opt) => {
  // console.log('Format option for size:', opt)
    return opt.name
  }

  const handleChooseOption = (e) => {
    console.log('Choose option:', e.target.value)
    const optionChosen = e.target.value
    if (optionChosen === 'create') {
      console.log('Create a new option')
      setShowNewOptionModal(true)
    } else {
      let pricePer = 0
      let newState = {}
      // Get the last price
      if (optionChosen && optionChosen._id) {
        newState.option = optionChosen._id
        let unitObj = {
          label: e.target.value.size,
          value: e.target.value.size
        }
        if (e.target.value && e.target.value.price && e.target.value.price > 0) {
          let markup = 2.25
          if (props.estimate && props.estimate.entity && props.estimate.entity === '640a56404965b71a9edd0e7d') {
            markup = 1.15
          }
          pricePer = parseFloat(e.target.value.price * markup).toFixed(2)
          newState.costPer = optionChosen.price
        }
        newState.unit = unitObj
        if (optionChosen && optionChosen.msrp) pricePer = parseFloat(optionChosen.msrp).toFixed(2)
        console.log('Set Price per;', pricePer)
        newState.pricePer = pricePer
        newState.totalPrice = Number(pricePer * state.qty).toFixed(2)
        newState.option = optionChosen
        console.log('SEt it', newState)
        setState({...state, ...newState })
      }
    }
  }

  const handleChange = (e) => {
    console.log('Set %s to %s ', e.target.id, e.target.value)
    let newState = { ...state, [e.target.id]: e.target.value }
    if (e.target.id === 'numberOfMen' || e.target.id === 'numberOfDays') {
      newState.suggestedLaborTotal = 560 * Number(newState.numberOfDays) * Number(newState.numberOfMen)
      newState.totalManHours = Number(newState.numberOfDays) * Number(newState.numberOfMen)
    }
    console.log('New State', newState)
    setState({...state, ...newState})
  }

  const handleOptionsUpdate = (data) => {
    console.log('Handle options update ', data)
  }

  const handleCloseNewOptionModal = (type, d) => {
    console.log('Type', type)
    console.log('Close mdoal', d)
    console.log('Inventory item', props.inventoryItem)
    setShowNewOptionModal(false)
    let newState = {}
    if (type === 'newOptionAdded') {
      // Item w/ new options returned
      const optionToSave = d.options[d.options.length -1]
      console.log('The last option is our new one', optionToSave)
      let unitObj = {
        label: optionToSave.size.name,
        value: optionToSave.size.name
      }
      let pricePer = 0
      if (optionToSave.msrp && parseFloat(optionToSave.msrp) > 0) {
        pricePer = parseFloat(optionToSave.msrp).toFixed(2)
      }
      newState.unit = unitObj
      newState.pricePer = pricePer
      newState.totalPrice = Number(pricePer * state.qty).toFixed(2)
      console.log('Set our state w/ new option...', newState)
      setState({...state, ...newState })
      props.updateParent(type, d)
    } else if (d && d.name) {
      // Add this new option...
      console.log('Add a new option', d)
      const optionToSave = {
        name: d.name,
        msrp: d.msrp,
        notes: d.notes,
        takeOff: props.inventoryItem._id,
        size: d?.size?.name,
        sizeId: d?.size?._id
      }
      console.log('Save thi soption', optionToSave)
      let unitObj = {
        label: optionToSave.size.name,
        value: optionToSave.size.name
      }
      let pricePer = 0
      if (optionToSave.msrp && parseFloat(optionToSave.msrp) > 0) {
        pricePer = parseFloat(optionToSave.msrp).toFixed(2)
      }
      newState.unit = unitObj
      newState.pricePer = pricePer
      newState.totalPrice = Number(pricePer * state.qty).toFixed(2)
      console.log('Set our state w/ new option...', newState)
      setState({...state, ...newState })
      props.updateParent('newOption', optionToSave)
    }
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      console.log(e.target)
      const { id, value } = e.target
      console.log('%s: %s ', id, value)
      let newArr = []
      let string = e.target.value
      if (e.target.id === 'chooseUnit') {

        unitsOfMeasureAll.filter(o => {
          let str = o['label'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          // setState({...state, salesRep: newArr[0]})
          handleUnitChange(e, newArr[0])
        }
      }
      if (e.target.id === 'chooseOption') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        props.inventoryItem.options.filter(o => {
          let str = o['name'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          // setState({...state, salesRep: newArr[0]})
          handleChooseOptionByAutoComplete(e, newArr[0])
        }
      }
    }
  }

  const handleChooseOptionByAutoComplete = (e, newItem) => {
    console.log('Choose by index', e)
    console.log('New item', newItem)
    if (newItem && newItem.value === 'create') {
      setShowNewOptionModal(true)
    } else if (newItem && newItem._id) {
      handleChooseOption({
        target: {
          value: newItem
        }
      })
    } else {
      handleClearOption()
    }
  }

  const handleClearOption = () => {
    const newState = {
      option: null,
      costPer: 0,
      pricePer: 0,
      totalPrice: 0,
      description: ''
    }
    setState({...state, ...newState })
  }

  const updateLineItemType = (val) => {
    if (!val) return null
    console.log('Update line item type 644', val)
    setState(prevState => (
      {
        ...prevState,
        lineItemType:val
      }
    ))
  }
  // Do we have scopes
  // console.log('props', props)
  // console.log(props.inventoryItem)
  let inventoryItem = {
    name: ''
  }
  if (props.inventoryItem && props.inventoryItem._id) inventoryItem = props.inventoryItem
  let scope = {
    name: 'Scope Name',
    _id: '448489348349'
  }

  const createOption = {
    _id: 'create',
    value: 'create',
    label: 'Create New',
    name: 'Add New Option'
  }
  const ourOptions = [...props.inventoryItem.options || [], createOption ] 
  // console.log('Our Options', ourOptions)
  console.log('State::::', state)
  return (
    <ConfigurationContainer>
      <AddNewOptionModal scope={scope} item={inventoryItem} sizes={sizes} open={showNewOptionModal} closeModal={handleCloseNewOptionModal} />
      <CalculationModal open={showCalculationModal} estimate={props.estimate} data={state} closeModal={handleCloseCalculationModal} updateParent={addCalculationsToEstimate} />
      <Grid container spacing={2}>
        {/* <Grid item lg={12} xs={12}>
          <LabelText>The following information will show up on the estimate, please type it up accordingly:</LabelText>
        </Grid> */}
        <Grid item lg={1} md={3} sm={4} xs={6}>
          <TextField
              label="Quantity"
              variant="outlined"
              type="text"
              value={state.qty}
              onChange={handleQtyUpdate}
              onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={6}>
          {
            (props.inventoryItem && props.inventoryItem.options && props.inventoryItem.options.length) ? (
             
              // <TextField
              //   fullWidth
              //   label="Option"
              //   variant="outlined"
              //   select
              //   placeholder='Selected Option'
              //   helperText="Which Option?"
              //   // className={classes.paddedStuff}
              //   onChange={handleChooseOption}
              //   style={{ textAlign: 'start' }}
              //   value={state.option}
              //   type="select">
              //   {
              //     props.inventoryItem.options.map((item, index) => {

              //       return (
              //         <MenuItem value={item} key={index}>{item.name} {(item.name === item.size) ? '' : `(${item.size})`} {item.notes}</MenuItem>
              //       )
              //     })
              //   }
              //    <MenuItem value={'create'} key={9999}>Add New Option</MenuItem>
              //   </TextField>
              <Autocomplete
                id="chooseOption"
                options={ourOptions || []}
                getOptionLabel={(option) => {
                  return `${option.name}`
                }}
                value={state.option}
                selectOnFocus
                // onBlur={handleUpdate}
                onChange={handleChooseOptionByAutoComplete}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Option" placeholder="Which Option?" onKeyDown={handleTabInteraction} />
                )}
              />
            ) : (
              // <TextField
              //   fullWidth
              //   label="Size"
              //   variant="outlined"
              //   select
              //   placeholder='Unit'
              //   helperText="Which Unit?"
              //   // className={classes.paddedStuff}
              //   onChange={handleUnitChange}
              //   style={{ textAlign: 'start' }}
              //   value={state.unit}
              //   onBlurResetsInput={false}
              //   isClearable={true}
              //   styles={customStyles}
              //   type="select">
              //   {
              //     unitsOfMeasureAll.map((item, index) => {

              //       return (
              //         <MenuItem value={item} key={index}>{item.label} </MenuItem>
              //       )
              //     })
              //   }
              //   <MenuItem value={'create'} key={9999}>Add New Option</MenuItem>
              // </TextField>
              <Autocomplete
                id="chooseUnit"
                options={unitsOfMeasureAll || []}
                getOptionLabel={(option) => {
                  return `${option.label}`
                }}
                value={state.unit}
                selectOnFocus
                isClearable={true}
                // onBlur={handleUpdate}
                onChange={handleUnitChange}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Option" placeholder="Which Unit?" onKeyDown={handleTabInteraction} />
                )}
            />
            )
          }
          
        </Grid>
        {/* <Grid item lg={1} md={3} sm={4} xs={6}>
          <TextField
            label="Material Budget"
            variant="outlined"
            type="number"
            value={state.materialBudget || 0}
            onChange={e=>setState({...state, materialBudget: e.target.value })}
            fullWidth
            />
        </Grid> */}

        <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Cost Per"
            variant="outlined"
            type="text"
            value={state.costPer}
            onChange={handleCostChangeUpdate}
            onFocus={handleFocus}
            onBlur={handleCostPerBlur}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Price Per"
            variant="outlined"
            type="text"
            value={state.pricePer}
            onChange={handlePriceManualChange}
            onFocus={handleFocus}
            onBlur={handleCostPerBlur}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Total Price"
            variant="outlined"
            type="text"
            value={state.totalPrice}
            // onChange={e=>setState({...state, totalPrice: e.target.value})}
            onFocus={handleFocus}
            disabled
            />
        </Grid>
        <Grid item lg={1} md={3} sm={4} xs={6}>
          <Tooltip title="Calculate" >
            <IconButton edge='end' onClick={handleCalculate} alt="Calculate" aria-label="Calculate">
              <AssessmentIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
         
        </Grid>
        {/* <Grid item lg={2} md={3} sm={3} xs={4}>
          <TextField
            label="Labor Hours"
            variant="outlined"
            type="number"
            value={state.laborHours || 0}
            onChange={e=>setState({...state, laborHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={3} xs={4}>
          <TextField
            label="Tech Hours"
            variant="outlined"
            type="number"
            value={state.techHours || 0}
            onChange={e=>setState({...state, techHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={3} xs={4}>
          <TextField
            label="Spray Tech Hours"
            variant="outlined"
            type="number"
            value={state.sprayTechHours || 0}
            onChange={e=>setState({...state, sprayTechHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={3} xs={4}>
          <TextField
            label="Operator Hours"
            variant="outlined"
            type="number"
            value={state.operatorHours || 0}
            onChange={e=>setState({...state, operatorHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={3} xs={4}>
          <TextField
            label="Supervisor Hours"
            variant="outlined"
            type="number"
            value={state.supervisorHours || 0}
            onChange={e=>setState({...state, supervisorHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>

        </Grid>
        <Grid item lg={2} md={3} sm={3} xs={4}>
          <TextField
            label="Bobcat Days"
            variant="outlined"
            type="number"
            value={state.bobcatDays || 0}
            onChange={e=>setState({...state, bobcatDays: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={3} xs={4}>
          <TextField
            label="Crane Days"
            variant="outlined"
            type="number"
            value={state.craneDays || 0}
            onChange={e=>setState({...state, craneDays: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            />
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            label="Line Item Output"
            variant="outlined"
            type="text"
            multiline
            minRows={5}
            spellCheck={true}
            value={state.description || ''}
            onChange={e=>setState({...state, description: e.target.value})}
            fullWidth
            onFocus={handleSelectFocus}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextareaAutosize
            label="Internal Notes"
            variant="outlined"
            type="text"
            
            style={{ width: '100%'}}
            minRows={6}
            spellCheck={true}
            value={state.internalNotes || ''}
            onChange={e=>setState({...state, internalNotes: e.target.value})}
            fullWidth
            onFocus={handleSelectFocus}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <LineItemTypeSelector currentType={state.lineItemType} setType={updateLineItemType} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            state.lineItemType === 'labor' && (
              <LaborDetails state={state} handleChange={handleChange} />
            )
          }
        </Grid>
       
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {
            state.editing ? <Button style={{margin: 10}} fullWidth variant="contained" color="primary" disabled={!state.description || props.estimate.accepted} onClick={handleUpdateExistingLineItem}>Update Line Item</Button> :
            <Button style={{margin: 10}} fullWidth variant="contained" color="primary" disabled={!state.description || props.estimate.accepted} onClick={handleSaveNewLineItem}>Save Line Item</Button>
          }
        </Grid>
      </Grid>
    </ConfigurationContainer>
  )
}

const LaborDetails = ({ state, handleChange }) => {
  console.log('Labor Details 963', state)
  return (
    <>
    <Grid container style={{ border: '2px solid #ccc', borderRadius: 7, padding: 10 }}>
    <Grid item lg={12} md={12} sm={12} xs={12}><LabelText>Labor Details:</LabelText></Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="# of Men"
          variant="outlined"
          type="number"
          id="numberOfMen"
          style={{ width: '100%'}}
          value={state.numberOfMen || ''}
          onChange={handleChange}
          fullWidth
          onFocus={handleFocus}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <TextField
          label="# of Days"
          variant="outlined"
          type="number"
          id="numberOfDays"
          style={{ width: '100%'}}
          value={state.numberOfDays || ''}
          onChange={handleChange}
          fullWidth
          onFocus={handleFocus}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <LabelText>{ state.totalManHours } Man Hours </LabelText>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12}>
        <LabelText> { currencyFormat(state.suggestedLaborTotal) } </LabelText>
      </Grid>
    </Grid>
  </>
  )
}

const mapStateToProps = (state) => {
  // console.log('MAP PROPS: ,', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ConfigureItem)
